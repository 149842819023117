/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Comment, Label } from 'semantic-ui-react';
import { Gallery, Item as GalleryItem } from 'react-photoswipe-gallery';


import getDateFormat from '../../../utils/get-date-format';
import { ActivityTypes } from '../../../constants/Enums';
import ItemComment from './ItemComment';
import User from '../../User';

import styles from './Item.module.scss';

const Item = React.memo(({ type, data, createdAt, user }) => {
  const [t] = useTranslation();


  let contentNode;
  switch (type) {
    case ActivityTypes.CREATE_CARD:
      contentNode = (
        <Trans
          i18nKey="common.userAddedThisCardToList"
          values={{
            user: user.name,
            list: data.list.name,
          }}
        >
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {' added this card to '}
            {data.list.name}
          </span>
        </Trans>
      );

      break;
    case ActivityTypes.MOVE_CARD:
      contentNode = (
        <Trans
          i18nKey="common.userMovedThisCardFromListToList"
          values={{
            user: user.name,
            fromList: data.fromList.name,
            toList: data.toList.name,
          }}
        >
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {' moved this card from '}
            {data.fromList.name}
            {' to '}
            {data.toList.name}
          </span>
        </Trans>
      );

      break;
    case ActivityTypes.CREATE_ATTACHMENT:
      console.log('data', data)
      contentNode = (
        <>
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {' uploaded attachment '}
            {data.attachment.name}
          </span>
          <div
          >
            <Gallery
              withCaption
              withDownloadButton
              options={{
                wheelToZoom: true,
                showHideAnimationType: 'none',
                closeTitle: '',
                zoomTitle: '',
                arrowPrevTitle: '',
                arrowNextTitle: '',
                errorMsg: '',
              }}
            >
              <GalleryItem
                key={data.attachment.id}
                original={data.attachment.path}
                caption={data.attachment.name}
                width={data.attachment?.image?.width}
                height={data.attachment?.image?.height}
              >
                {({ ref, open }) =>
                  <div ref={ref} onClick={() => open()} className={styles.wrapper}>
                    <div
                      style={{
                        background: data.attachment.path && `url("${data.attachment.path}") center / cover`,
                        borderRadius: "3px",
                        height: "80px",
                        left: 0,
                        textAlign: "center",
                        textDecoration: "none",
                        top: "50%",
                        width: "112px"
                      }}
                    >
                      <Label
                        corner="left"
                        size="mini"
                        icon={{
                          name: 'star',
                          color: 'grey',
                          inverted: true,
                        }}
                        className={styles.thumbnailLabel}
                        style={{ borderColor: "rgba(29, 46, 63, 0.8)" }}
                      />
                    </div>
                  </div>
                }
              </GalleryItem>
            </Gallery>
          </div>
        </>
      )
      break;
    case ActivityTypes.DELETE_ATTACHMENT:
      contentNode = (
        <>
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {' deleted attachment '}
            {data.item.name}
          </span>
        </>
      )
      break;
    case ActivityTypes.UPDATE_ATTACHMENT:
      contentNode = (
        <>
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {' renamed attachment '}
            {data.old.name}
            {' to '}
            {data.new.name}
          </span>
        </>
      )
      break;
    case ActivityTypes.ARCHIVE_CARD:
      contentNode = (
        <>
          <span className={styles.author}>{user.name}</span>
          <span className={styles.text}>
            {data.card.archived ? ' archived this card' : ' restored this card'}
          </span>
        </>
      )
      break;

    default:
      contentNode = null;
  }

  return (
    <Comment>
      <span className={styles.user}>
        <User name={user.name} avatarUrl={user.avatarUrl} />
      </span>
      <div className={classNames(styles.content)}>
        <div>{contentNode}</div>
        <span className={styles.date}>
          {t(`format:${getDateFormat(createdAt)}`, {
            postProcess: 'formatDate',
            value: createdAt,
          })}
        </span>
      </div>
    </Comment>
  );
});

Item.Comment = ItemComment;

Item.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  createdAt: PropTypes.instanceOf(Date).isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Item;
