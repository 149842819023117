import EntryActionTypes from '../constants/EntryActionTypes';

const updateTaskGroup = (data) => ({
  type: EntryActionTypes.TASK_GROUP_UPDATE,
  payload: {
    data,
  },
});

const handleTaskGroupUpdate = (data) => ({
  type: EntryActionTypes.TASK_GROUP_UPDATE_HANDLE,
  payload: {
    data,
  },
});

const handleTaskGroupCreate = (data) => ({
  type: EntryActionTypes.TASK_GROUP_CREATE_HANDLE,
  payload: {
    data,
  },
});

const handleTaskGroupDelete = (data) => ({
  type: EntryActionTypes.TASK_GROUP_DELETE_HANDLE,
  payload: {
    data,
  },
});

const createTaskGroup = (data) => ({
  type: EntryActionTypes.TASK_GROUP_CREATE,
  payload: {
    data,
  },
});

const deleteTaskGroup = (data) => ({
  type: EntryActionTypes.TASK_GROUP_DELETE,
  payload: {
    data,
  },
});

export default {
  updateTaskGroup,
  handleTaskGroupUpdate,
  handleTaskGroupCreate,
  handleTaskGroupDelete,
  createTaskGroup,
  deleteTaskGroup,
};
