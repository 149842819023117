import EntryActionTypes from '../constants/EntryActionTypes';

const createSettings = (data) => ({
  type: EntryActionTypes.CREATE_SETTINGS,
  payload: {
    data,
  },
});

const updateSettings = (data) => ({
  type: EntryActionTypes.UPDATE_SETTINGS,
  payload: {
    data,
  },
});

export default {
  createSettings,
  updateSettings,
};

