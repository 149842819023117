import { call, put, select } from 'redux-saga/effects';
import actions from '../../../actions';
import { defaultSettingsObject } from '../../../models/Settings';

export function* createSettings() {
  yield put(actions.createSettings(defaultSettingsObject));
}

function* updateSettings(settings) {
  console.log('updateSettingsSettings', settings);
  yield put(actions.updateSettings(settings));
}

export default {
  createSettings,
  updateSettings,
};
