import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* projectMembersWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MEMBER_IN_CURRENT_PROJECT_CREATE, ({ payload: { data } }) =>
      services.createMemberInCurrentProject(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_MEMBER_CREATE_HANDLE, ({ payload: { projectMember } }) =>
      services.handleProjectMemberCreate(projectMember),
    ),
    takeEvery(EntryActionTypes.PROJECT_MEMBER_DELETE, ({ payload: { id } }) =>
      services.deleteProjectMember(id),
    ),
    takeEvery(EntryActionTypes.PROJECT_MEMBER_DELETE_HANDLE, ({ payload: { projectMember } }) =>
      services.handleProjectMemberDelete(projectMember),
    ),
  ]);
}
