/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import Paths from '../../constants/Paths';
import { ProjectBackgroundTypes } from '../../constants/Enums';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-icon.svg';
import { ListManager } from '../../dnd/ListManager';

import styles from './Projects.module.scss';
import globalStyles from '../../styles.module.scss';

// eslint-disable-next-line react/prop-types
function ProjectElement({ item, canAdd, onAdd }) {
  const [t] = useTranslation();
  if (item.id === 'add') {
    if (!canAdd) {
      return null;
    }
    return (
      <button type="button" className={classNames(styles.card, styles.add)} onClick={onAdd}
        style={{
          width: "200px",
          margin: "10px",
        }}
      >
        <div className={styles.addTitleWrapper}>
          <div className={styles.addTitle}>
            <PlusIcon className={styles.addGridIcon} />
            {t('action.createProject')}
          </div>
        </div>
      </button>
    )
  }
  return (
    <Link
      to={
        item.firstBoardId
          ? Paths.BOARDS.replace(':id', item.firstBoardId)
          : Paths.PROJECTS.replace(':id', item.id)
      }
    >
      <div
        className={classNames(
          styles.card,
          styles.open,
          item.background &&
          item.background.type === ProjectBackgroundTypes.GRADIENT &&
          globalStyles[`background${upperFirst(camelCase(item.background.name))}`],
        )}
        style={{
          width: "200px",
          margin: "10px",
          background:
            item.background &&
            item.background.type === 'image' &&
            `url("${item.backgroundImage.coverUrl}") center / cover`,
        }}
      >
        {item.notificationsTotal > 0 && (
          <span className={styles.notification}>{item.notificationsTotal}</span>
        )}
        <div className={styles.cardOverlay} />
        <div className={styles.openTitle}>{item.name}</div>
      </div>
    </Link>
  );
}


function sort(list) {
  return list.slice().sort((first, second) => first.position - second.position);
}

const Projects = React.memo(({ items, canAdd, onAdd, onMove }) => {
  const [sortedList, setSortedList] = useState(items);
  const sortList = () => {
    const sorted = sort(sortedList);
    setSortedList(sorted);
  };

  useMemo(
    () => {
      setSortedList(items);
    }, [items])

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedList;
    const destination = list[destinationIndex];
    if (destination.id === 'add') {
      console.log('add cancel drag')
      return // cancel anything replacing add
    }

    onMove(list[sourceIndex].id, sourceIndex, destinationIndex)

    if (destinationIndex === 0) {
      list[sourceIndex].position = list[0].position - 1;
      sortList();
      return;
    }

    if (destinationIndex === list.length - 1) {
      list[sourceIndex].position = list[list.length - 1].position + 1;
      sortList();
      return;
    }

    if (destinationIndex < sourceIndex) {
      list[sourceIndex].position =
        (list[destinationIndex].position + list[destinationIndex - 1].position) / 2;

      sortList();
      return;
    }

    list[sourceIndex].position =
      (list[destinationIndex].position + list[destinationIndex + 1].position) / 2;
    sortList();
  };


  return (
    <Container className={styles.cardsWrapper}>
      <div className={styles.fixWrapper}>
        <ListManager
          items={sortedList}
          direction="horizontal"
          maxItems={4}
          render={(item) => <ProjectElement item={item} canAdd={canAdd} onAdd={onAdd} />}
          onDragEnd={reorderList}
          disabled={!canAdd}
        />
      </div>
    </Container>
  );
});

Projects.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canAdd: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default Projects;
