import EntryActionTypes from '../constants/EntryActionTypes';

const createMemberInCurrentProject = (data) => ({
  type: EntryActionTypes.MEMBER_IN_CURRENT_PROJECT_CREATE,
  payload: {
    data,
  },
});

const handleProjectMemberCreate = (projectMember) => ({
  type: EntryActionTypes.PROJECT_MEMBER_CREATE_HANDLE,
  payload: {
    projectMember,
  },
});

const deleteProjectMember = (id) => ({
  type: EntryActionTypes.PROJECT_MEMBER_DELETE,
  payload: {
    id,
  },
});

const handleProjectMemberDelete = (projectMember) => ({
  type: EntryActionTypes.PROJECT_MEMBER_DELETE_HANDLE,
  payload: {
    projectMember,
  },
});

export default {
  createMemberInCurrentProject,
  deleteProjectMember,
  handleProjectMemberCreate,
  handleProjectMemberDelete,
};
