import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Projects from '../components/Projects';

const mapStateToProps = (state) => {
  const { allowAllToCreateProjects } = selectors.selectConfig(state);
  const { isAdmin } = selectors.selectCurrentUser(state);
  const projects = selectors.selectProjectsForCurrentUser(state);
  if (projects.findIndex((project) => project.id === 'add') === -1) {
    projects.push({
      id: 'add',
      position: Infinity,
    });
  }

  return {
    items: projects,
    canAdd: allowAllToCreateProjects || isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAdd: entryActions.openProjectAddModal,
      onMove: (id, oldIndex, newIndex) => entryActions.moveProject(id, oldIndex, newIndex),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
