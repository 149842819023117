import ActionTypes from '../constants/ActionTypes';

const createGlobalLabel = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_CREATE,
  payload: {
    label,
  },
});

createGlobalLabel.success = (localId, label) => ({
  type: ActionTypes.GLOBAL_LABEL_CREATE__SUCCESS,
  payload: {
    localId,
    label,
  },
});

createGlobalLabel.failure = (localId, error) => ({
  type: ActionTypes.GLOBAL_LABEL_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleGlobalLabelCreate = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_CREATE_HANDLE,
  payload: {
    label,
  },
});

const addGlobalLabelToCard = (id, cardId) => ({
  type: ActionTypes.GLOBAL_LABEL_TO_CARD_ADD,
  payload: {
    id,
    cardId,
  },
});

addGlobalLabelToCard.success = (cardLabel) => ({
  type: ActionTypes.GLOBAL_LABEL_TO_CARD_ADD__SUCCESS,
  payload: {
    cardLabel,
  },
});

addGlobalLabelToCard.failure = (cardLabel, error) => ({
  type: ActionTypes.GLOBAL_LABEL_TO_CARD_ADD__FAILURE,
  payload: {
    cardLabel,
    error,
  },
});

const removeGlobalLabelFromCard = (id, cardId) => ({
  type: ActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE,
  payload: {
    id,
    cardId,
  },
});

removeGlobalLabelFromCard.success = (cardLabel) => ({
  type: ActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE__SUCCESS,
  payload: {
    cardLabel,
  },
});

removeGlobalLabelFromCard.failure = (id, cardId, error) => ({
  type: ActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE__FAILURE,
  payload: {
    id,
    cardId,
    error,
  },
});

const updateGlobalLabel = (id, data) => ({
  type: ActionTypes.GLOBAL_LABEL_UPDATE,
  payload: {
    id,
    data,
  },
});

updateGlobalLabel.success = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_UPDATE__SUCCESS,
  payload: {
    label,
  },
});

updateGlobalLabel.failure = (id, error) => ({
  type: ActionTypes.GLOBAL_LABEL_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleGlobalLabelUpdate = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_UPDATE_HANDLE,
  payload: {
    label,
  },
});

const handleGlobalLabelToCardAdd = (cardLabel) => ({
  type: ActionTypes.GLOBAL_LABEL_TO_CARD_ADD_HANDLE,
  payload: {
    cardLabel,
  },
});

const deleteGlobalLabel = (id) => ({
  type: ActionTypes.GLOBAL_LABEL_DELETE,
  payload: {
    id,
  },
});

deleteGlobalLabel.success = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_DELETE__SUCCESS,
  payload: {
    label,
  },
});

deleteGlobalLabel.failure = (id, error) => ({
  type: ActionTypes.GLOBAL_LABEL_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleGlobalLabelDelete = (label) => ({
  type: ActionTypes.GLOBAL_LABEL_DELETE_HANDLE,
  payload: {
    label,
  },
});

const handleGlobalLabelFromCardRemove = (cardLabel) => ({
  type: ActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE_HANDLE,
  payload: {
    cardLabel,
  },
});

export default {
  createGlobalLabel,
  addGlobalLabelToCard,
  removeGlobalLabelFromCard,
  handleGlobalLabelToCardAdd,
  handleGlobalLabelDelete,
  deleteGlobalLabel,
  handleGlobalLabelFromCardRemove,
  handleGlobalLabelUpdate,
  updateGlobalLabel,
  handleGlobalLabelCreate,
};
