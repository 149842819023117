import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';

import Memberships from '../Memberships';

import styles from './ManagersPane.module.scss';

const ManagersPane = React.memo(({ items, allUsers, onCreate, onDelete, type }) => {
  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <Memberships
        items={items}
        allUsers={allUsers}
        title="common.managers"
        addTitle={type === "manager" ? "common.addManager" : 'Add Member'}
        actionsTitle="common.managerActions"
        leaveButtonContent="action.leaveProject"
        leaveConfirmationTitle="common.leaveProject"
        leaveConfirmationContent="common.areYouSureYouWantToLeaveProject"
        leaveConfirmationButtonContent="action.leaveProject"
        deleteButtonContent="action.removeFromProject"
        deleteConfirmationTitle={type === "manager" ? "common.removeManager" : 'Remvoe Member'}
        deleteConfirmationContent={type === "manager" ? "common.areYouSureYouWantToRemoveThisManagerFromProject" : 'Are you sure you want to remove this member from the project?'}
        deleteConfirmationButtonContent={type === "manager" ? "action.removeManager" : 'Remove member'}
        canLeaveIfLast={false}
        onCreate={onCreate}
        onDelete={onDelete}
      />
    </Tab.Pane>
  );
});

ManagersPane.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string
};

ManagersPane.defaultProps = {
  type: undefined
}

export default ManagersPane;
