import EntryActionTypes from '../constants/EntryActionTypes';

const createTaskInCurrentCard = (data) => ({
  type: EntryActionTypes.TASK_IN_CURRENT_CARD_CREATE,
  payload: {
    data,
  },
});

const handleTaskCreate = (task) => ({
  type: EntryActionTypes.TASK_CREATE_HANDLE,
  payload: {
    task,
  },
});

const updateTask = (id, data) => ({
  type: EntryActionTypes.TASK_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleTaskUpdate = (task) => ({
  type: EntryActionTypes.TASK_UPDATE_HANDLE,
  payload: {
    task,
  },
});

const moveTask = ({groupId, taskId, index}) => ({
  type: EntryActionTypes.TASK_MOVE,
  payload: {
    id: taskId,
    index,
    groupId,
  },
});

const deleteTask = (id) => ({
  type: EntryActionTypes.TASK_DELETE,
  payload: {
    id,
  },
});

const handleTaskDelete = (task) => ({
  type: EntryActionTypes.TASK_DELETE_HANDLE,
  payload: {
    task,
  },
});

const createUsersOnTask = (data) => ({
  type: EntryActionTypes.USERS_ON_TASK_CREATE,
  payload: {
    data,
  },
});

const deleteUsersOnTask = (data) => ({
  type: EntryActionTypes.USERS_ON_TASK_DELETE,
  payload: {
    data,
  },
});

const handleUserToTaskAdd = (data) => ({
  type: EntryActionTypes.USER_TO_TASK_ADD_HANDLE,
  payload: {
    data,
  },
});

const handleUserFromTaskRemove = (data) => ({
  type: EntryActionTypes.USER_FROM_TASK_REMOVE_HANDLE,
  payload: {
    data,
  },
});

export default {
  createTaskInCurrentCard,
  handleTaskCreate,
  updateTask,
  handleTaskUpdate,
  moveTask,
  deleteTask,
  handleTaskDelete,
  createUsersOnTask,
  deleteUsersOnTask,
  handleUserToTaskAdd,
  handleUserFromTaskRemove,
};
