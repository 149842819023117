import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import entryActions from '../entry-actions';
import selectors from '../selectors';
import ModalTypes from '../constants/ModalTypes';
import GlobalLabel from '../components/GlobalLabelModal';

const mapStateToProps = (state) => {
  const currentModal = selectors.selectCurrentModal(state);
  const allGlobalLabels = selectors.selectGlobalLabels(state);
  const projectsToLists = selectors.selectProjectsToListsForCurrentUser(state);
  return {
    isSettingsModalOpened: currentModal === ModalTypes.GLOBAL_LABELS,
    allGlobalLabels,
    projectsToLists,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createGlobalLabel,
      onClose: entryActions.closeModal,
      onLabelCreate: entryActions.createGlobalLabel,
      onLabelUpdate: entryActions.updateGlobalLabel,
      onBoardFetch: entryActions.fetchBoard,
      onLabelDelete: entryActions.deleteGlobalLabel,
      onGlobalLabelUpdate: entryActions.updateGlobalLabel,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GlobalLabel);
