/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import hash from 'object-hash';


export const withReactToItemsChange = Component => (
  props
) => <Component key={hash(props.items)} {...props} />;
