import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeSelectListById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ List }, id) => {
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      return {
        ...listModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectListById = makeSelectListById();

export const makeSelectCardIdsByListId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ List }, id) => {
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      return listModel.getFilteredOrderedCardsModelArray().map((cardModel) => cardModel.id);
    },
  );

export const selectArchivedCardIdsByListId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ List }, id) => {
      const listModel = List.withId(id);
      if (!listModel) {
        return listModel;
      }
      return listModel
        .getFilteredOrderedCardsModelArray()
        .map((cardModel) => {
          if (cardModel.archived) {
            return cardModel.id;
          }
          return false;
        })
        .filter(Boolean);
    },
  );

export const selectCardIdsByListId = makeSelectCardIdsByListId();

export const getCollapsedLists = () =>
  createSelector(orm, ({ Settings }) => {
    const settings = Settings.first();
    if (!settings || !settings?.collapsedList) return [];
    const l = settings.collapsedList.toRefArray().map((list) => list.id);
    if (!l) return [];
    return l;
  });

export default {
  makeSelectListById,
  selectListById,
  selectArchivedCardIdsByListId,
  makeSelectCardIdsByListId,
  selectCardIdsByListId,
  getCollapsedLists,
};
