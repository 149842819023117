import { attr, fk, many } from 'redux-orm';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'TaskGroup';

  static fields = {
    id: attr(),
    name: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    cardId: fk({
      to: 'Card',
      as: 'card',
      relatedName: 'taskGroup',
    }),
    syncTaskGroupId: attr(),
    tasks: many('Task', 'taskGroup'),
  };

  static reducer({ type, payload }, TaskGroup) {
    switch (type) {
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.taskGroups) {
          payload.taskGroups.forEach((taskGroup) => {
            TaskGroup.upsert(taskGroup);
          });
        }
        break;
      case ActionTypes.TASK_GROUP_CREATE:
      case ActionTypes.TASK_GROUP_CREATE_HANDLE:
        TaskGroup.upsert(payload.taskGroup);
        break;
      case ActionTypes.TASK_GROUP_CREATE__SUCCESS:
        TaskGroup.withId(payload.id).delete();
        TaskGroup.upsert(payload.taskGroup);
        break;

      case ActionTypes.TASK_GROUP_UPDATE:
      case ActionTypes.TASK_GROUP_UPDATE_HANDLE:
        TaskGroup.withId(payload.task.id).update(payload.task);
        break;

      case ActionTypes.TASK_GROUP_CREATE__FAILURE:
        TaskGroup.withId(payload.id).delete();
        break;

      case ActionTypes.TASK_GROUP_DELETE:
      case ActionTypes.TASK_GROUP_DELETE_HANDLE:
        TaskGroup.withId(payload.id).delete();
        break;

      default:
    }
  }
}
