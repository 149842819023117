import { call, put, select } from 'redux-saga/effects';
import { createLocalId } from '../../../utils/local-id';
import request from '../request';
import actions from '../../../actions';
import api from '../../../api';

export function* updateTaskGroup(data) {
  const { id } = data;
  yield put(actions.updateTaskGroup(data));

  let taskGroup;
  try {
    ({ item: taskGroup } = yield call(request, api.updateTaskGroup, id, {
      name: data.name,
      cardId: data.cardId,
    }));
  } catch (error) {
    console.log(error);
    yield put(actions.updateTaskGroup.failure(id, error));
    return;
  }

  yield put(actions.updateTaskGroup.success(taskGroup));
}
export function* handleCreateTaskGroup(data) {
  yield put(actions.createTaskGroup(data));
}
export function* handleDeleteTaskGroup(data) {
  yield put(actions.deleteTaskGroup(data));
}

export function* handleUpdateTaskGroup(data) {
  yield put(actions.updateTaskGroup(data));
}

export function* createTaskGroup(data) {
  const localId = yield call(createLocalId);
  yield put(
    actions.createTaskGroup({
      id: localId,
      name: `Checklist${data.totalGroups > 0 ? ' ' + data.totalGroups: ''}`, // eslint-disable-line
      cardId: data.cardId,
    }),
  );
  let taskGroup;
  try {
    ({ item: taskGroup } = yield call(request, api.createTaskGroup, data.cardId, {
      name: `Checklist${data.totalGroups > 0 ? ' ' + data.totalGroups: ''}`, // eslint-disable-line
      cardId: data.cardId,
    }));
  } catch (error) {
    yield put(actions.createTaskGroup.failure(data.id, error));
    return;
  }

  yield put(actions.createTaskGroup.success(localId, taskGroup));
}

export function* deleteTaskGroup(data) {
  yield put(actions.deleteTaskGroup(data));

  let taskGroup;
  try {
    ({ item: taskGroup } = yield call(request, api.deleteTaskGroup, data.id, data));
  } catch (error) {
    console.log(error);
    yield put(actions.deleteTaskGroup.failure(data.id, error));
    return;
  }

  yield put(actions.deleteTaskGroup.success(taskGroup));
}

export default {
  updateTaskGroup,
  createTaskGroup,
  deleteTaskGroup,
  handleCreateTaskGroup,
  handleDeleteTaskGroup,
  handleUpdateTaskGroup,
};
