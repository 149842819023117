import { createSelector } from 'redux-orm';
import orm from '../orm';


export const selectSettings = createSelector(
  orm,
  session => {
    return session.Settings.first();
  }
);


export default {
  selectSettings,
}
