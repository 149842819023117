import socket from './socket';

/* Transformers */

export const transformProjectMember = (projectMember) => ({
  ...projectMember,
  createdAt: new Date(projectMember.createdAt),
});

/* Actions */

const createProjectMember = (projectId, data, headers) =>
  socket.post(`/projects/${projectId}/members`, data, headers).then((body) => ({
    ...body,
    item: transformProjectMember(body.item),
  }));

const deleteProjectMember = (id, headers) =>
  socket.delete(`/project-members/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformProjectMember(body.item),
  }));

/* Event handlers */

const makeHandleProjectMemberCreate = (next) => (body) => {
  next({
    ...body,
    item: transformProjectMember(body.item),
  });
};

const makeHandleProjectMemberDelete = makeHandleProjectMemberCreate;

export default {
  createProjectMember,
  deleteProjectMember,
  makeHandleProjectMemberCreate,
  makeHandleProjectMemberDelete,
};
