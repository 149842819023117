/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-arrow-function-lifecycle */

import React from "react";
import { splitItems, computeOriginalIndex, computeOriginalIndexAfterDrop } from "./compute";

// eslint-disable-next-line import/prefer-default-export
export const withMaxItems = (
  Component,
  createId
) => {
  return class ComponentWithMaxItems extends React.Component {
    constructor(props) {
      super(props);
      const maxItems = props.maxItems && props.maxItems > 0 ? props.maxItems : props.items.length;
      this.state = {
        maxItems,
        items: props.items,
        chunks: splitItems(maxItems, props.items, createId)
      };
    }

    findChunkIndex = (id) => {
      return this.state.chunks.findIndex((chunk) => chunk.id === id);
    }

    onDragEnd = ({ source, destination }) => {
      if (destination) {
        const { index: indexInSourceChunk, id: sourceChunkId } = source;
        const { index: indexInDestinationChunk, id: destinationChunkId } = destination;
        const sourceChunkIndex = this.findChunkIndex(sourceChunkId);
        const destinationChunkIndex = this.findChunkIndex(destinationChunkId);
        const sourceIndex = computeOriginalIndex(this.state.maxItems, sourceChunkIndex, indexInSourceChunk);
        const destinationIndex = computeOriginalIndexAfterDrop(
          this.state.maxItems,
          sourceChunkIndex,
          destinationChunkIndex,
          indexInDestinationChunk
        );
        this.props.onDragEnd(sourceIndex, destinationIndex);
      }
    }

    render = () => {
      const { items, maxItems, onDragEnd, ...rest } = this.props;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component chunks={this.state.chunks} onDragEnd={this.onDragEnd} {...(rest)} />;
    }
  };
};
