import ActionTypes from '../constants/ActionTypes';

const createTask = (task) => ({
  type: ActionTypes.TASK_CREATE,
  payload: {
    task,
  },
});

createTask.success = (localId, task) => ({
  type: ActionTypes.TASK_CREATE__SUCCESS,
  payload: {
    localId,
    task,
  },
});

createTask.failure = (localId, error) => ({
  type: ActionTypes.TASK_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleTaskCreate = (task) => ({
  type: ActionTypes.TASK_CREATE_HANDLE,
  payload: {
    task,
  },
});

const updateTask = (id, data) => ({
  type: ActionTypes.TASK_UPDATE,
  payload: {
    id,
    data,
  },
});

updateTask.success = (task) => ({
  type: ActionTypes.TASK_UPDATE__SUCCESS,
  payload: {
    task,
  },
});

updateTask.failure = (id, error) => ({
  type: ActionTypes.TASK_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleTaskUpdate = (task) => ({
  type: ActionTypes.TASK_UPDATE_HANDLE,
  payload: {
    task,
  },
});

const deleteTask = (id) => ({
  type: ActionTypes.TASK_DELETE,
  payload: {
    id,
  },
});

deleteTask.success = (task) => ({
  type: ActionTypes.TASK_DELETE__SUCCESS,
  payload: {
    task,
  },
});

deleteTask.failure = (id, error) => ({
  type: ActionTypes.TASK_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleTaskDelete = (task) => ({
  type: ActionTypes.TASK_DELETE_HANDLE,
  payload: {
    task,
  },
});

const addUserToTask = (memberId, taskId, cardId, isCurrent) => ({
  type: ActionTypes.USER_TO_TASK_ADD,
  payload: {
    memberId,
    taskId,
    cardId,
    isCurrent,
  },
});

addUserToTask.success = (cardMembership) => ({
  type: ActionTypes.USER_TO_TASK_ADD__SUCCESS,
  payload: {
    cardMembership,
  },
});

addUserToTask.failure = (cardId, userId, error) => ({
  type: ActionTypes.USER_TO_TASK_ADD__FAILURE,
  payload: {
    cardId,
    userId,
    error,
  },
});

const handleUserToTaskAdd = ({ memberId, taskId, cardId }) => ({
  type: ActionTypes.USER_TO_TASK_ADD_HANDLE,
  payload: {
    memberId,
    taskId,
    cardId,
  },
});

const removeUserFromTask = (memberId, taskId, cardId) => ({
  type: ActionTypes.USER_FROM_TASK_REMOVE,
  payload: {
    memberId,
    taskId,
    cardId,
  },
});

removeUserFromTask.success = (cardMembership) => ({
  type: ActionTypes.USER_FROM_TASK_REMOVE__SUCCESS,
  payload: {
    cardMembership,
  },
});

removeUserFromTask.failure = (cardId, userId, error) => ({
  type: ActionTypes.USER_FROM_TASK_REMOVE__FAILURE,
  payload: {
    cardId,
    userId,
    error,
  },
});

const handleUserFromTaskRemove = ({ memberId, taskId, cardId }) => ({
  type: ActionTypes.USER_FROM_TASK_REMOVE_HANDLE,
  payload: {
    memberId,
    taskId,
    cardId,
  },
});

export default {
  createTask,
  handleTaskCreate,
  updateTask,
  handleTaskUpdate,
  deleteTask,
  handleTaskDelete,
  addUserToTask,
  handleUserToTaskAdd,
  removeUserFromTask,
  handleUserFromTaskRemove,
};
