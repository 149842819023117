import { createSelector } from 'redux-orm';

import orm from '../orm';

export const selectTaskGroupFromTaskGroupIds = () =>
  createSelector(
    orm,
    (_, ids) => ids,
    ({ TaskGroup }, ids) => {
      if (!ids) {
        return [];
      }

      return ids
        .map((id) => {
          const taskGroupModel = TaskGroup.withId(id);
          if (!taskGroupModel) {
            return false;
          }
          return taskGroupModel.ref;
        })
        .filter(Boolean);
    },
  );

export const selectTaskGroupsFromCardIds = () =>
  createSelector(
    orm,
    (_, cardIds) => cardIds,
    ({ TaskGroup }, cardIds) => {
      if (!cardIds) {
        return [[]];
      }
      return cardIds
        .map((cardId) => {
          const taskGroupModel = TaskGroup.filter({ cardId }).toModelArray();
          if (!taskGroupModel) {
            return false;
          }
          return taskGroupModel.map((model) => model.ref);
        })
        .filter(Boolean);
    },
  );

export default {
  selectTaskGroupFromTaskGroupIds,
  selectTaskGroupsFromCardIds,
};
