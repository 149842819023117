import socket from './socket';

const createTaskGroup = (id, data, headers) => socket.post(`/tasks/groups/${id}`, data, headers);

const updateTaskGroup = (id, data, headers) => socket.patch(`/tasks/groups/${id}`, data, headers);

const deleteTaskGroup = (id, data, headers) => socket.delete(`/tasks/groups/${id}`, data, headers);

export default {
  updateTaskGroup,
  createTaskGroup,
  deleteTaskGroup,
};
