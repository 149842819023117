/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */

// components.js
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Button as SemanticButton, Icon as SemanticIcon } from 'semantic-ui-react';

import './components.scss';

export const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => {
    const color = reversed
      ? active
        ? 'text-white'
        : 'text-[#aaa]'
      : active
        ? 'text-black'
        : 'text-[#ccc]';

    return (
      <SemanticButton
        {...props}
        ref={ref}
        className={`${color}${className ? ` ${className} ` : ` `}cursor-pointer bg-none border-none transition-colors duration-200 hover:text-blue-500 !w-7 !p-1 flex items-center justify-items-center`}
      />);
  }
);

export const Icon = React.forwardRef(({ className, name, ...props }, ref) => (
  <SemanticIcon
    {...props}
    ref={ref}
    className={`${className ? `${className}` : ''} !m-0`}
    name={name}
    size="small"
    style={{ verticalAlign: 'middle' }}
  />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={classNames(
      'flex flex-wrap items-center bg-white border border-gray-300 border-b-0 rounded-t mb-0 p-2',
      className
    )}
    onClick={(e) => e.preventDefault()}
  />
));

export const Menu = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => {
    console.log(props);
    console.log(className);
    return (
      <div
        {...props}
        data-test-id="menu"
        ref={ref}
        className={`${className} menu`}
      />
    )
  }
)

export const Portal = ({ children }) => {
  console.log(document);
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

