import ActionTypes from '../constants/ActionTypes';

const createTaskGroup = (taskGroup) => ({
  type: ActionTypes.TASK_GROUP_CREATE,
  payload: {
    taskGroup,
  },
});

createTaskGroup.failure = (id, error) => ({
  type: ActionTypes.TASK_GROUP_CREATE__FAILURE,
  payload: {
    id,
    error,
  },
});

createTaskGroup.success = (id, taskGroup) => ({
  type: ActionTypes.TASK_GROUP_CREATE__SUCCESS,
  payload: {
    id,
    taskGroup,
  },
});

const updateTaskGroup = (task) => ({
  type: ActionTypes.TASK_GROUP_UPDATE,
  payload: {
    task,
  },
});

updateTaskGroup.failure = (task, error) => ({
  type: ActionTypes.TASK_GROUP_UPDATE__FAILURE,
  payload: {
    task,
    error,
  },
});

updateTaskGroup.success = (task) => ({
  type: ActionTypes.TASK_GROUP_UPDATE__SUCCESS,
  payload: {
    task,
  },
});

const deleteTaskGroup = (taskGroup) => ({
  type: ActionTypes.TASK_GROUP_DELETE,
  payload: {
    id: taskGroup.id,
  },
});

deleteTaskGroup.failure = (taskGroup, error) => ({
  type: ActionTypes.TASK_GROUP_DELETE__FAILURE,
  payload: {
    taskGroup,
    error,
  },
});

deleteTaskGroup.success = (task) => ({
  type: ActionTypes.TASK_GROUP_DELETE__SUCCESS,
  payload: {
    task,
  },
});

export default {
  updateTaskGroup,
  deleteTaskGroup,
  createTaskGroup,
};
