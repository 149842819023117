/* eslint-disable no-param-reassign */

import { visit } from 'unist-util-visit';

import { Transforms, Element as SlateElement } from 'slate';
import debug from 'debug';

const $debug = debug('slate:plugins:mentions');

export const insertMention = (editor, user) => {
  const userObject = {
    id: user.id,
    username: user.username,
    name: user.username,
    // NOTE: dont want to store email in text ever
    email: '',
  };
  const mention = {
    type: 'mention',
    user: userObject,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const withMentions = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === 'mention' || markableVoid(element);
  };

  return editor;
};

export const remarkMention = () => {
  return (tree) => {
    visit(tree, 'text', (node, index, parent) => {
      const mentionRegex = /@\((.*?)\)\[(.*?)\]/g;
      const matches = Array.from(node.value.matchAll(mentionRegex));

      if (matches.length > 0) {
        const newChildren = [];
        let lastIndex = 0;

        matches.forEach((match) => {
          const { 0: fullMatch, index: matchIndex } = match;

          if (matchIndex > lastIndex) {
            newChildren.push({
              type: 'text',
              value: node.value.slice(lastIndex, matchIndex),
            });
          }

          const [username, id] = fullMatch.match(/@\((.*?)\)\[(.*?)\]/).slice(1, 3);

          const user = {
            id,
            username,
            name: username,
            email: ``,
          };

          newChildren.push({
            type: 'text',
            value: '',
          });

          newChildren.push({
            type: 'mention',
            user,
            children: [{ text: '' }],
          });

          newChildren.push({
            type: 'text',
            value: '',
          });

          lastIndex = matchIndex + fullMatch.length;
        });

        if (lastIndex < node.value.length) {
          newChildren.push({
            type: 'text',
            value: node.value.slice(lastIndex),
          });
        }

        parent.children.splice(index, 1, ...newChildren);
      }
    });
  };
};

export default {
  insertMention,
  withMentions,
  remarkMention,
};
