import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createGlobalLabel(data) {
  const localId = yield call(createLocalId);
  const nextData = {
    ...data,
    position: 1,
  };

  yield put(
    actions.createGlobalLabel({
      ...nextData,
      id: localId,
    }),
  );

  try {
    const { item: globalLabel } = yield call(request, api.createGlobalLabel, nextData);
    yield put(actions.createGlobalLabel.success(localId, globalLabel));
  } catch (error) {
    yield put(actions.createGlobalLabel.failure(localId, error));
  }
}

export function* handleGlobalLabelCreate(label) {
  yield put(actions.handleGlobalLabelCreate(label));
}

export function* handleGlobalLabelUpdate(label) {
  yield put(actions.handleGlobalLabelUpdate(label));
}

export function* updateGlobalLabel(id, data) {
  const serverId = id.replace('global-', '');
  yield put(actions.updateGlobalLabel(serverId, data));

  let label;
  try {
    ({ item: label } = yield call(request, api.updateGlobalLabel, serverId, data));
  } catch (error) {
    yield put(actions.updateGlobalLabel.failure(serverId, error));
    return;
  }

  yield put(actions.updateGlobalLabel.success(label));
}

export function* deleteGlobalLabel(id) {
  yield put(actions.deleteGlobalLabel(id));
  let label;
  try {
    ({ item: label } = yield call(request, api.deleteGlobalLabel, id));
  } catch (error) {
    yield put(actions.deleteGlobalLabel.failure(id, error));
    return;
  }

  yield put(actions.deleteGlobalLabel.success(label));
}

export function* handleGlobalLabelDelete(id) {
  yield put(actions.handleGlobalLabelDelete(id));
}

export function* addGlobalLabelToCard(id, cardId) {
  yield put(actions.addGlobalLabelToCard(id, cardId));
  let cardLabel;
  try {
    ({ item: cardLabel } = yield call(request, api.addGlobalLabelToCard, cardId, {
      labelId: id,
    }));
    yield put(actions.addGlobalLabelToCard.success(cardLabel));
  } catch (error) {
    yield put(actions.addGlobalLabelToCard.failure(cardLabel, error));
    return;
  }

  yield put(actions.addGlobalLabelToCard.success(cardLabel));
}
export function* removeGlobalLabelFromCard(id, cardId) {
  yield put(actions.removeGlobalLabelFromCard(id, cardId));

  let cardLabel;
  try {
    ({ item: cardLabel } = yield call(request, api.deleteCardGlobalLabel, cardId, id));
  } catch (error) {
    yield put(actions.removeGlobalLabelFromCard.failure(id, cardId, error));
    return;
  }

  yield put(actions.removeGlobalLabelFromCard.success(cardLabel));
}

export function* addGlobalLabelToCurrentCard(id) {
  const { cardId } = yield select(selectors.selectPath);
  yield call(addGlobalLabelToCard, id, cardId);
}

export function* removeGlobalLabelFromCurrentCard(id) {
  const { cardId } = yield select(selectors.selectPath);

  yield call(removeGlobalLabelFromCard, id, cardId);
}

export function* handleGlobalLabelToCardAdd(cardLabel) {
  yield put(actions.handleGlobalLabelToCardAdd(cardLabel));
}

export function* handleGlobalLabelFromCardRemove(cardLabel) {
  yield put(actions.handleGlobalLabelFromCardRemove(cardLabel));
}

export default {
  createGlobalLabel,
  updateGlobalLabel,
  deleteGlobalLabel,
  addGlobalLabelToCurrentCard,
  removeGlobalLabelFromCurrentCard,
  addGlobalLabelToCard,
  removeGlobalLabelFromCard,
  handleGlobalLabelToCardAdd,
  handleGlobalLabelFromCardRemove,
  handleGlobalLabelCreate,
  handleGlobalLabelUpdate,
  handleGlobalLabelDelete,
};
