import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* taskGroupWatchers() {
  yield all([
    takeEvery(EntryActionTypes.TASK_GROUP_UPDATE, ({ payload: { data } }) =>
      services.updateTaskGroup(data),
    ),
    takeEvery(EntryActionTypes.TASK_GROUP_CREATE, ({ payload: { data } }) =>
      services.createTaskGroup(data),
    ),
    takeEvery(EntryActionTypes.TASK_GROUP_DELETE, ({ payload: { data } }) =>
      services.deleteTaskGroup(data),
    ),
    takeEvery(EntryActionTypes.TASK_GROUP_UPDATE_HANDLE, ({ payload: { data } }) =>
      services.handleUpdateTaskGroup(data),
    ),
    takeEvery(EntryActionTypes.TASK_GROUP_CREATE_HANDLE, ({ payload: { data } }) =>
      services.handleCreateTaskGroup(data),
    ),
    takeEvery(EntryActionTypes.TASK_GROUP_DELETE_HANDLE, ({ payload: { data } }) =>
      services.handleDeleteTaskGroup(data),
    ),
  ]);
}
