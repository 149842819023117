import socket from './socket';

const createGlobalLabel = (data, headers) => socket.post('/global/labels', data, headers);

const deleteGlobalLabel = (id, headers) =>
  socket.delete(`/global/labels/${id}`, undefined, headers);

const updateGlobalLabel = (id, data, headers) =>
  socket.patch(`/global/labels/${id}`, data, headers);

const getGlobalLabels = (subscribe, headers) =>
  socket.get(`/global/labels${subscribe ? '?subscribe=true' : ''}`, undefined, headers);

const addGlobalLabelToCard = (id, data, headers) =>
  socket.post(`/global/labels/${id}/cards`, data, headers);

const deleteCardGlobalLabel = (cardId, labelId, headers) =>
  socket.delete(`/global/cards/${cardId}/labels/${labelId}`, undefined, headers);

export default {
  createGlobalLabel,
  getGlobalLabels,
  addGlobalLabelToCard,
  deleteCardGlobalLabel,
  updateGlobalLabel,
  deleteGlobalLabel,
};
