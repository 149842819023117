import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectProjectMemberById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ ProjectMember }, id) => {
      const projectMemberModel = ProjectMember.withId(id);

      if (!projectMemberModel) {
        return projectMemberModel;
      }

      return projectMemberModel.ref;
    },
  );

export const selectProjectMemberById = makeSelectProjectMemberById();

export default {
  makeSelectProjectMemberById,
  selectProjectMemberById,
};
