/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import hash from "object-hash";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ListManagerItem } from "./ListManagerItem";

const horizontalStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start"
};

export const DragAndDropWrapper = ({
  onDragEnd,
  chunks,
  direction,
  render,
  disabled
}) => {
  return (
    <DragDropContext onDragEnd={mapAndInvoke(onDragEnd)} d>
      {chunks.map(({ id: droppableId, items }) => (
        <Droppable key={droppableId} droppableId={droppableId} direction={direction}>
          {(provided, _) => (
            <div
              ref={provided.innerRef}
              style={direction === "horizontal" ? horizontalStyle : undefined}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <ListManagerItem key={hash(item)} item={item} index={index} render={render} disabled={disabled} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
};

function mapAndInvoke(onDragEnd) {
  return function({ source, destination }) {
    if (destination !== undefined && destination !== null) {
      const result = {
        source: {
          id: source.droppableId,
          index: source.index
        },
        destination: {
          id: destination.droppableId,
          index: destination.index
        }
      };
      onDragEnd(result);
    }
  };
}
