import { createSelector } from 'redux-orm';

import { isLocalId } from '../utils/local-id';

import orm from '../orm';

export const selectGlobalLabels = createSelector(orm, (session) => {
  const { GlobalLabel } = session;
  return GlobalLabel.all()
    .toRefArray()
    .map((label) => {
      return {
        ...label,
        id: `global-${label.id}`,
        isGlobal: true,
        isPersisted: !isLocalId(label.id),
      };
    });
});

export default {
  selectGlobalLabels,
};
