import { attr, many } from 'redux-orm';

import BaseModel from './BaseModel';

export const defaultSettingsObject = {
  showDetails: false,
  collapsedList: [],
};

export default class extends BaseModel {
  static modelName = 'Settings';

  static fields = {
    id: attr(),
    showDetails: attr(),
    collapsedList: many('List', 'collapsedLists'),
  };

  static reducer({ type, payload }, Settings) {
    switch (type) {
      case 'CREATE_SETTINGS':
      case 'SETTINGS_UPDATE':
        Settings.upsert(payload);
        break;
      case 'ACTIVITIES_DETAILS_TOGGLE':
        Settings.first().update({ showDetails: payload.isVisible });
        break;
      case 'LIST_COLLAPSE_IN_BOARD': {
        let settings = Settings.first();
        if (!settings) {
          Settings.upsert(defaultSettingsObject);
          settings = Settings.first();
        }

        if (!settings?.collapsedList) {
          settings.upsert({ collapsedList: [] });
          settings.collapsedList.upsert(payload.id);
        } else {
          const collapsedLists = settings.collapsedList.toRefArray();
          const isActive = collapsedLists.some((list) => list.id === payload.id);
          if (isActive) settings.collapsedList.remove(payload.id);
          else settings.collapsedList.add(payload.id);
        }
        break;
      }
      default:
        break;
    }
  }
}
