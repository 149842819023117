import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const formatTime = (dateValue) => {
  const inputDate = new Date(dateValue);
  const currentDate = new Date();

  const diffMs = currentDate - inputDate;
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);

  const isToday =
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear();

  if (isToday) {
    if (diffSeconds < 60) {
      return `just now`;
    }
    if (diffMinutes < 60) {
      return `${diffMinutes} min${diffMinutes > 1 ? 's' : ''} ago`;
    }
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  }

  const day = inputDate.getDate().toString().padStart(2, "0");

  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  return `${day}/${month}/${year}`;
};

const Time = React.memo(({ date }) => {
  const [formattedTime, setFormattedTime] = useState(formatTime(date));

  useEffect(() => {
    const diffMs = new Date() - new Date(date);
    const diffSeconds = Math.floor(diffMs / 1000);

    const updateInterval = diffSeconds < 60 ? 1000 : 60000;

    const intervalId = setInterval(() => {
      setFormattedTime(formatTime(date));
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{formattedTime}</span>;
});

Time.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default Time;

