/* eslint-disable no-else-return */
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './StoryPoints.module.scss';

const SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
};

const StoryPoints = React.memo(({ value, size, isDisabled, onClick }) => {
  const determineColor = () => {
    if (value <= 2) {
      return { 'backgroundColor': '#D3F1A7', color: '#37571F' };
    } else if (value <= 5) {
      return { 'backgroundColor': '#164B35', color: '#BAF3DB' };
    } else if (value <= 13) {
      return { 'backgroundColor': '#533F04', color: '#F8E6A0' };
    } else {
      return { 'backgroundColor': '#702E00', color: '#FEDEC8' };
    }
  };

  const contentNode = (
    <span
      className={classNames(
        styles.wrapper,
        styles[`wrapper${upperFirst(size)}`],
        onClick && styles.wrapperHoverable,
      )}
      style={determineColor()}
    >
      {value}
    </span>
  );

  return onClick ? (
    <button type="button" disabled={isDisabled} className={styles.button} onClick={onClick}>
      {contentNode}
    </button>
  ) : (
    contentNode
  );
});

StoryPoints.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

StoryPoints.defaultProps = {
  size: SIZES.MEDIUM,
  isDisabled: false,
  onClick: undefined,
};

export default StoryPoints;
