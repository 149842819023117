/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disast_ble react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-else-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */

import { Editor, Transforms, Element as SlateElement, Node, Range, Point, Text, Path } from 'slate';
import isHotkey, { isKeyHotkey } from 'is-hotkey';

import debug from 'debug';

import { insertMention, withMentions, remarkMention } from '../plugins/Mentions/Mentions';

const $debug = debug('app:slate:utils');
const alignment = ['left', 'center', 'right', 'justify'];
const listTypes = ['orderedList', 'unorderedList'];

const HOTKEYS = {
  'mod+b': 'strong',
  'mod+i': 'emphasis',
  'mod+`': 'code',
};

export const toggleBlock = (editor, format, customValue) => {
  $debug('toggleBlock', format);
  $debug('customValue', customValue);
  const isActive = isBlockActive(editor, format, customValue);
  const isList = listTypes.includes(format);

  $debug('isActive', isActive);
  $debug('isList', isList);

  Transforms.unwrapNodes(editor, {
    match: (n) => listTypes.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });

  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    ...customValue,
  };

  Transforms.setNodes(editor, newProperties);

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }
};

export const isBlockActive = (editor, format, customValue) => {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Editor.nodes(editor, {
    match: (n) => {
      if (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format) {
        for (const key in customValue) {
          if (n[key] !== customValue[key]) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
  });

  if (match) {
    $debug('match', match);
    $debug('format', format);
  }

  return !!match;
};

function handleArrowKey(editor, event, direction) {
  const debug = $debug.extend('handleArrowKey');
  const { selection } = editor;
  debug('handleArrowKey called with direction: %s', direction);

  if (selection && Range.isCollapsed(selection)) {
    debug('Selection is collapsed');
    const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link' });

    if (link) {
      debug('Link found: %O', link);
      const linkPoint =
        direction === 'ArrowRight' ? Editor.end(editor, link[1]) : Editor.start(editor, link[1]);
      debug('linkPoint: %O', linkPoint);

      if (Point.equals(selection.anchor, linkPoint)) {
        debug('Selection anchor is at the linkPoint');
        const nextPoint =
          direction === 'ArrowRight'
            ? Editor.after(editor, link[1])
            : Editor.before(editor, link[1]);
        debug('nextPoint: %O', nextPoint);

        if (nextPoint) {
          const block = Editor.above(editor, {
            match: (n) => Editor.isBlock(editor, n),
          });
          debug('Block found: %O', block);

          if (block) {
            const [, blockPath] = block;
            const blockLimit =
              direction === 'ArrowRight'
                ? Editor.end(editor, blockPath)
                : Editor.start(editor, blockPath);
            debug('blockLimit: %O', blockLimit);

            const nextBlock = Editor.above(editor, {
              at: nextPoint,
              match: (n) => Editor.isBlock(editor, n),
            });
            debug('nextBlock: %O', nextBlock);

            const isNewBlock = nextBlock && !Path.equals(nextBlock[1], blockPath);
            debug('isNewBlock: %s', isNewBlock);

            if (blockLimit && Point.compare(nextPoint, blockLimit) <= 0 && !isNewBlock) {
              debug('Inside block limit and not a new block');
              const [nextNode] = Editor.nodes(editor, {
                at: nextPoint,
                match: (n) => Text.isText(n) && n.text.length > 0,
              });
              debug('nextNode: %O', nextNode);

              if (nextNode) {
                debug('Next node found, moving cursor to nextPoint');
                Transforms.select(editor, nextPoint);
              } else {
                debug('No next node found, inside blockLimit');
                Transforms.select(editor, nextPoint);
                event.preventDefault();
              }
            } else if (isNewBlock) {
              debug('Preventing movement to a new block');
              event.preventDefault();
            }
          }
        }
      }
    }
  }
}

export const onKeyDown = ({
  event,
  editor,
  isLocked,
  mentions,
  index,
  target,
  setIndex,
  setTarget,
}) => {
  if (isLocked) return;
  const { selection } = editor;
  // NOTE: this breaks out of links if someone arrows right at the end of a link
  if (event.key === 'ArrowRight') {
    handleArrowKey(editor, event, 'ArrowRight');
  }
  if (event.key === 'ArrowLeft') {
    handleArrowKey(editor, event, 'ArrowLeft');
  }

  if (target && mentions.length > 0) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        const prevIndex = index >= mentions.length - 1 ? 0 : index + 1;
        setIndex(prevIndex);
        break;
      case 'ArrowUp':
        event.preventDefault();
        const nextIndex = index <= 0 ? mentions.length - 1 : index - 1;
        setIndex(nextIndex);
        break;
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        Transforms.select(editor, target);
        insertMention(editor, mentions[index]);
        setTarget(null);
        break;
      case 'Escape':
        event.preventDefault();
        setTarget(null);
        break;

      default:
        break;
    }
  }
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};

export const getActiveBlockType = (editor) => {
  const { selection } = editor;
  if (!selection) return '';

  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
  });

  return match ? match[0].type : '';
};

export const toggleMark = (editor, format, customValue = true) => {
  const isActive = isMarkActive(editor, format, customValue);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, customValue);
  }
};

export const isMarkActive = (editor, format, customValue = true) => {
  const marks = Editor.marks(editor);
  if (!marks) return false;

  if (marks[format]) {
    if (typeof customValue === 'object') {
      for (const key in customValue) {
        if (marks[format][key] !== customValue[key]) {
          return false;
        }
      }
      return true;
    } else {
      return marks[format] === customValue;
    }
  }

  return false;
};

export default {
  toggleBlock,
  isBlockActive,
  getActiveBlockType,
  onKeyDown,
  toggleMark,
  isMarkActive,
};
