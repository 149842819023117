import { attr, fk, many } from 'redux-orm';

import { createLocalId } from '../utils/local-id';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'Task';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    isCompleted: attr({
      getDefault: () => false,
    }),
    cardId: fk({
      to: 'Card',
      as: 'card',
      relatedName: 'tasks',
    }),
    users: many('User', 'tasks'),
    taskGroup: fk({
      to: 'TaskGroup',
      as: 'group',
      relatedName: 'taskGroup',
    }),
  };

  static reducer({ type, payload }, Task) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.PROJECT_MEMBER_CREATE_HANDLE:
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.CARD_UPDATE_HANDLE:
        if (payload.tasks) {
          payload.tasks.forEach((task) => {
            Task.upsert(task);
          });
        }

        if (payload.taskMemberships) {
          payload.taskMemberships.forEach((taskMembership) => {
            Task.withId(taskMembership.taskId).users.add(taskMembership.userId);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Task.all().delete();

        if (payload.tasks) {
          payload.tasks.forEach((task) => {
            Task.upsert(task);
          });
        }

        break;
      case ActionTypes.BOARD_FETCH__SUCCESS:
      case ActionTypes.CARD_CREATE_HANDLE:
      case ActionTypes.CARD_DUPLICATE__SUCCESS:
        payload.tasks.forEach((task) => {
          Task.upsert(task);
        });

        break;
      case ActionTypes.CARD_DUPLICATE:
        payload.taskIds.forEach((taskId, index) => {
          const taskModel = Task.withId(taskId);

          Task.upsert({
            ...taskModel.ref,
            id: `${createLocalId()}-${index}`, // TODO: hack?
            cardId: payload.card.id,
          });
        });

        break;
      case ActionTypes.TASK_CREATE:
      case ActionTypes.TASK_CREATE_HANDLE:
      case ActionTypes.TASK_UPDATE__SUCCESS:
      case ActionTypes.TASK_UPDATE_HANDLE:
        Task.upsert(payload.task);

        break;
      case ActionTypes.TASK_CREATE__SUCCESS:
        Task.withId(payload.localId).delete();
        Task.upsert(payload.task);

        break;
      case ActionTypes.TASK_UPDATE:
        Task.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.TASK_DELETE:
        Task.withId(payload.id).delete();

        break;
      case ActionTypes.TASK_DELETE__SUCCESS:
      case ActionTypes.TASK_DELETE_HANDLE: {
        try {
          const taskModel = Task.withId(payload.task.id);
          if (taskModel) {
            taskModel.delete();
          }
        } catch (e) {} // eslint-disable-line no-empty
        break;
      }

      case ActionTypes.USER_TO_TASK_ADD__SUCCESS:
      case ActionTypes.USER_TO_TASK_ADD_HANDLE:
      case ActionTypes.USER_TO_TASK_ADD: {
        try {
          const taskModel = Task.withId(payload.taskId);
          if (taskModel) taskModel.users.add(payload.memberId);
        } catch (e) {} // eslint-disable-line no-empty
        break;
      }

      case ActionTypes.USER_FROM_TASK_REMOVE__SUCCESS:
      case ActionTypes.USER_FROM_TASK_REMOVE_HANDLE:
      case ActionTypes.USER_FROM_TASK_REMOVE: {
        const taskModel = Task.withId(payload.taskId);

        if (taskModel) {
          taskModel.users.remove(payload.memberId);
        }
        break;
      }

      default:
    }
  }
}
