import EntryActionTypes from '../constants/EntryActionTypes';

const createGlobalLabel = (data) => ({
  type: EntryActionTypes.GLOBAL_LABEL_CREATE,
  payload: {
    data,
  },
});

const updateGlobalLabel = (id, data) => ({
  type: EntryActionTypes.GLOBAL_LABEL_UPDATE,
  payload: {
    id,
    data,
  },
});

const deleteGlobalLabel = (id) => ({
  type: EntryActionTypes.GLOBAL_LABEL_DELETE,
  payload: {
    id,
  },
});

const handleGlobalLabelDelete = (id) => ({
  type: EntryActionTypes.GLOBAL_LABEL_DELETE_HANDLE,
  payload: {
    id,
  },
});

const handleGlobalLabelToCardAdd = (cardLabel) => ({
  type: EntryActionTypes.GLOBAL_LABEL_TO_CARD_ADD_HANDLE,
  payload: {
    cardLabel,
  },
});

const removeGlobalLabelFromCard = (id, cardId) => ({
  type: EntryActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE,
  payload: {
    id,
    cardId,
  },
});

const handleGlobalLabelFromCardRemove = (cardLabel) => ({
  type: EntryActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE_HANDLE,
  payload: {
    cardLabel,
  },
});

const handleGlobalLabelCreate = (label) => ({
  type: EntryActionTypes.GLOBAL_LABEL_CREATE_HANDLE,
  payload: {
    label,
  },
});

const handleGlobalLabelUpdate = (label) => ({
  type: EntryActionTypes.GLOBAL_LABEL_UPDATE_HANDLE,
  payload: {
    label,
  },
});

export default {
  createGlobalLabel,
  updateGlobalLabel,
  deleteGlobalLabel,
  handleGlobalLabelToCardAdd,
  removeGlobalLabelFromCard,
  handleGlobalLabelFromCardRemove,
  handleGlobalLabelCreate,
  handleGlobalLabelUpdate,
  handleGlobalLabelDelete,
};
