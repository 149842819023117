// NOTE: this file should be a copy of the server/client file
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-else-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/alt-text */

import { unified } from 'unified';
import debug from 'debug';

import stringify from 'remark-stringify';
import frontmatter from 'remark-frontmatter';
import gfm from 'remark-gfm';
import { slateToRemark, remarkToSlate } from 'remark-slate-transformer';
import markdown from 'remark-parse';
import { remarkMention } from '../plugins/Mentions/Mentions';

const $debug = debug('app:slate:utils:slateConvert');

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

// FIXME: markdown does not support this need custom handling
// only convert in the backend when sending discord messages maybe?
// would then need to convert old text to new text?
// this would prevent any data loss if something goes wrong with the conversion
export const mdToSlate = (markdownString) => {
  try {
    $debug('Parsing markdown:', markdownString);
    if (markdownString && markdownString.length > 0) {
      const result = unified()
        .use(markdown)
        .use(gfm)
        .use(frontmatter)
        .use(remarkMention)
        .use(remarkToSlate, {
          overrides: {
            mention: (node, next) => ({
              type: 'mention',
              user: node.user,
              children: [{ text: '' }],
            }),
            list: (node, next) => {
              return {
                ...node,
                type: node.ordered ? 'orderedList' : 'unorderedList',
                children: next(node.children),
              };
            },
            listItem: (node, next) => {
              $debug('listItem node:', node);
              return {
                ...node,
                type: 'list-item',
                // NOTE: by default conversion is of type paragraph when it should be just text. This stops creating new list and toggling of a list
                children:
                  node.children.length > 0
                    ? next(node.children)
                    : [
                        {
                          text: '',
                        },
                      ],
              };
            },
          },
        })
        .processSync(markdownString).result;

      $debug('Parsed Slate value:', result);
      return Array.isArray(result) && result.length > 0 ? result : initialValue;
    } else {
      $debug('Using initialValue as editor value');
      return initialValue;
    }
  } catch (error) {
    console.error('Error parsing markdown:', error);
    return initialValue;
  }
};

export const slateToMd = (slateValue) => {
  try {
    const processor = unified().use(gfm).use(frontmatter).use(stringify);
    $debug('Slate value:', slateValue);

    const ast = processor.runSync(
      slateToRemark(slateValue, {
        overrides: {
          mention: (node, next) => ({
            type: 'text',
            value: `@(${node.user.username})[${node.user.id}]`,
          }),
          orderedList: (node, next) => {
            $debug('orderedList node:', node);
            return {
              type: 'list',
              ordered: true,
              children: node.children.map((child) => ({
                type: 'listItem',
                children: next(child.children),
              })),
            };
          },
          unorderedList: (node, next) => {
            $debug('unorderedList node:', node);
            return {
              type: 'list',
              ordered: false,
              children: node.children.map((child) => ({
                type: 'listItem',
                children: next(child.children),
              })),
            };
          },
        },
      }),
    );
    $debug('Slate AST:', ast);
    const text = processor.stringify(ast);
    $debug('Serialized Markdown:', text);
    return text;
  } catch (error) {
    console.error('Error serializing Slate value to markdown:', error);
    return '';
  }
};

export const serialize = (value) => {
  return JSON.stringify(value);
};

export const deserialize = (string) => {
  if (!string) return initialValue;

  try {
    $debug('Deserializing', string);
    if (typeof value === 'number') return mdToSlate(string);
    $debug('Deserializing JSON.parse():', string);
    return JSON.parse(string);
  } catch (error) {
    $debug('Error deserializing JSON.parse():', error);
    return mdToSlate(string);
  }
};

export default {
  slateToMd,
  mdToSlate,
  serialize,
  deserialize,
};
