/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-else-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */

import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
  createContext,
  useImperativeHandle
} from 'react'

import PropTypes from 'prop-types';

import { Editable, withReact, useSlate, Slate, ReactEditor, useSelected, useFocused, useSlateStatic, } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Text,
  Range,
  Node as SlateNode,
} from 'slate';


import debug from 'debug';


import { withHistory, HistoryEditor } from 'slate-history';

// import "github-markdown-css";

import { Button, Icon, Toolbar, Portal } from './components';
import User from '../User';
import { toggleBlock, isBlockActive, onKeyDown, isMarkActive, toggleMark } from './utils/SlateUtilityFunctions';
import { deserialize, serialize, slateToMd } from './utils/slateConvert';
import EditorContext from './EditorContext';
import {
  insertMention,
  withMentions,
} from './plugins/Mentions/Mentions';

import { withImages, InsertImageButton } from './plugins/Images/Images';

import { withShortcuts, SHORTCUTS } from './plugins/markdown-shortcuts/markdown-shortcuts';

const allowedSchemes = ['http:', 'https:']

const $debug = debug('app:slate:editor');

export const IS_MAC =
  typeof navigator !== 'undefined' && /Mac OS X/.test(navigator.userAgent)

export const IS_ANDROID =
  typeof navigator !== 'undefined' && /Android/.test(navigator.userAgent)

const WYSIWYG = React.forwardRef(({ isLocked, value, onChange, mentionList, editorPlaceholder, showToolbar }, ref) => {
  const mentionPortal = useRef();
  const [target, setTarget] = useState(null)
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const [editor] = useState(() => withShortcuts(withImages(withMentions(withReact(withHistory(createEditor()))))));
  const [initialValue, setInitalValue] = useState(value);

  const [editorValue, setEditorValue] = useState(deserialize(initialValue));

  const mentions = mentionList.filter(c => {
    return (
      c.name.toLowerCase().includes(search.toLowerCase()) ||
      c.username.toLowerCase().includes(search.toLowerCase()) ||
      c.id.includes(search.toLowerCase()) ||
      c.email && c.email.toLowerCase().includes(search.toLowerCase())
    );
  }
  ).slice(0, 10)

  const updateEditorValue = useCallback((newValue) => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
    Transforms.removeNodes(editor, {
      at: [0],
    })

    setEditorValue(newValue);
    Transforms.insertNodes(
      editor,
      newValue
    )
  }, [editor])

  const refocusEditor = useCallback(() => {
    $debug('refocusEditor started')
    const editorEnd = Editor.end(editor, [])
    // if editor is empty 
    console.log(Editor.string(editor, []))
    if (editor && (Editor.isEmpty(editor, editor) || Editor.string(editor, []).trim() === '')) {

      const block = Editor.above(editor, {
        match: (n) => {
          return Editor.isBlock(editor, n)
        }
        ,
      }, []);

      const path = block ? block[1] : [];
      Transforms.setSelection(editor, path);
      ReactEditor.focus(editor);
      ReactEditor.focus(editor);
      $debug('refocusEditor block', path);
    } else {
      $debug('refocusEditor editorEnd', editorEnd)
      Transforms.select(editor, editorEnd)
      ReactEditor.focus(editor);
    }
  }, [editor])

  useEffect(() => {
    const deserializeValue = deserialize(value);
    if (initialValue !== deserializeValue) {
      $debug('initialValue', initialValue)
      $debug('deserializeValue', deserializeValue)
      updateEditorValue(deserializeValue);
      // WARN: do i need to rest inital value?
    }
  }, [initialValue, value, updateEditorValue])


  useEffect(() => {
    if (target && mentions.length > 0) {
      if (mentionPortal.current) {
        const el = mentionPortal.current
        const domRange = ReactEditor.toDOMRange(editor, target)
        const rect = domRange.getBoundingClientRect()
        el.style.top = `${rect.top + window.pageYOffset + 24}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [mentions.length, editor, index, search, target])

  const handleChange = newValue => {
    if (isLocked) return;
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange)
        setSearch(beforeMatch[1])
        setIndex(0)
        return
      }
    }
    const slate = serialize(newValue);
    const markdown = slateToMd(newValue);
    if (onChange) {
      onChange({ slate: slate ?? '', markdown: markdown ? markdown : null });
    }
  };

  const onKeyDownEvent = useCallback(
    event => onKeyDown({
      event,
      editor,
      isLocked,
      mentions,
      index,
      target,
      setIndex,
      setTarget
    }), [editor, isLocked, mentions, index, target]
  );

  const contextValue = useMemo(() => ({ editor }), [editor]);

  const undo = () => {
    HistoryEditor.undo(editor)
  }

  const redo = () => {
    HistoryEditor.redo(editor)
  }


  const insertReplyClick = useCallback((user) => {
    const newUser = {
      id: user.id,
      name: user.name,
      username: user.username,
    }
    insertMention(editor, newUser)
    refocusEditor()
  }, [editor, refocusEditor])

  const resetEditor = useCallback(() => {
    updateEditorValue(deserialize(initialValue));
  }, [initialValue, updateEditorValue])

  useImperativeHandle(
    ref,
    () => ({
      insertReplyClick,
      refocusEditor,
      resetEditor
    }),
    [insertReplyClick, refocusEditor, resetEditor]
  );

  return (
    <EditorContext.Provider value={contextValue}>
      <Slate editor={contextValue.editor} initialValue={editorValue} onChange={handleChange}>
        {!isLocked && showToolbar && (
          <Toolbar>
            <MarkButton format="strong" icon="bold" />
            <MarkButton format="emphasis" icon="italic" />
            <MarkButton format="delete" icon="delete" />
            <BlockButton format="heading" icon="heading" customValue={{ depth: 1 }} />
            <BlockButton format="heading" icon="heading" customValue={{ depth: 2 }} />
            <BlockButton format="blockquote" icon="quote left" />
            <BlockButton format="orderedList" icon="numbered list" />
            <BlockButton format="unorderedList" icon="list" />
            {/* FIXME: markdown does not support this need custom handling */}
            {/* <BlockButton format="align" icon="align left" customValue={{ align: 'left' }} /> */}
            {/* <BlockButton format="align" icon="align center" customValue={{ align: 'center' }} /> */}
            {/* <BlockButton format="align" icon="align right" customValue={{ align: 'right' }} /> */}
            {/* <BlockButton format="align" icon="align justify" customValue={{ align: 'justify' }} /> */}
            <InsertImageButton />
            <CommandButton icon="undo" action={undo} />
            <CommandButton icon="redo" action={redo} />
          </Toolbar>
        )}
        <Editable
          renderElement={renderElement}
          // onDOMBeforeInput={handleDOMBeforeInput}
          renderLeaf={renderLeaf}
          placeholder={editorPlaceholder}
          spellCheck
          autoFocus
          readOnly={isLocked}
          onKeyDown={onKeyDownEvent}
          className=" p-2 border border-gray-300 rounded-b-lg bg-gray-50"
          renderPlaceholder={({ attributes }) => (
            <span
              {...attributes}
              className="mt-2"
            >
              {editorPlaceholder}
            </span>
          )}
        />

        {target && mentions.length > 0 && (
          <Portal>
            <div
              ref={mentionPortal}
              className="absolute z-50 bg-white border border-gray-300 rounded-lg shadow-md"
              style={{ top: '-9999px', left: '-9999px', zIndex: 10001 }}
              data-cy="mentions-portal"
            >
              {mentions.map((user, i) => (
                <div
                  key={user.id}
                  onClick={(event) => {
                    event.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, user);
                    setTarget(null);
                  }}
                  className={`p-2 rounded cursor-pointer transition hover:bg-blue-200 ${i === index ? 'bg-blue-200' : 'bg-transparent'
                    }`}
                >
                  <User
                    name={user.name}
                    avatarUrl={user.avatarUrl}
                    size="medium"
                    className="flex-shrink-0"
                  />
                  <span className="ml-2 text-sm flex-shrink-0">{user.username}</span>
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate >
    </EditorContext.Provider>
  );
});

const Element = props => {
  const { attributes, children, element, } = props;
  const editor = useSlateStatic()
  const style = { textAlign: element.align };
  attributes.style = style;
  const safeUrl = useMemo(() => {
    let parsedUrl = null
    try {
      parsedUrl = new URL(element.url)
      // eslint-disable-next-line no-empty
    } catch { }
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href
    }
    return 'about:blank'
  }, [element.url])

  switch (element.type) {
    case 'mention':
      return <Mention {...props} />;
    case "paragraph":
      return <p {...attributes}>{children}</p>;
    case "heading": {
      switch (element.depth) {
        case 1:
          return <h1 {...attributes}>{children}</h1>;
        case 2:
          return <h2 {...attributes}>{children}</h2>;
        case 3:
          return <h3 {...attributes}>{children}</h3>;
        case 4:
          return <h4 {...attributes}>{children}</h4>;
        case 5:
          return <h5 {...attributes}>{children}</h5>;
        case 6:
          return <h6 {...attributes}>{children}</h6>;
        default:
          break;
      }
      break;
    }
    case "blockquote":
      return (
        <span className="text-gray-500">
          <blockquote className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50" {...attributes}>{children}</blockquote>
          <p className='text-gray-500' />
        </span>
      )

    case 'list-item':
      return <li {...attributes} {...element.attr}>{children}</li>
    case 'orderedList':
      return <ol type='1' className='list-decimal ml-5' {...attributes}>{children}</ol>
    case 'unorderedList':
      return <ul {...attributes} className='list-disc ml-5'>{children}</ul>
    case 'image':
      return <Image {...props} />
    case 'link':
      return <a {...attributes} href={safeUrl} onClick={(e) => {
        const target = e.currentTarget
        if (ReactEditor.isReadOnly(editor)) {
          window.open(target.href, '_blank')
          e.stopPropagation()
          e.preventDefault()
        } else if (e.metaKey || e.ctrlKey) {
          window.open(target.href, '_blank')
          e.stopPropagation()
          e.preventDefault()
        }
      }} className="text-blue-600 hover:text-slate-700">{children}</a>
    default:
      return <div {...attributes}>{children}</div>
  }

  return <div {...attributes}>{children}</div>
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.strong) {
    children = <strong>{children}</strong>;
  }
  if (leaf.emphasis) {
    children = <em>{children}</em>;
  }
  if (leaf.delete) {
    children = <del>{children}</del>;
  }
  if (leaf.inlineCode) {
    children = <code>{children}</code>;
  }
  return <span {...attributes}>{children}</span>;
};

const CommandButton = ({ icon, action }) => {
  return (
    <Button onClick={action}>
      <Icon name={icon} size="small" />
    </Button>
  )
}

const BlockButton = ({ format, icon, customValue }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        customValue
      )}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format, customValue);
      }}
    >
      <Icon name={icon} size="small" />
    </Button>
  );
};

const MarkButton = ({ format, icon, customValue }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format, customValue)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format, customValue);
      }}
    >
      <Icon name={icon} size="small" />
    </Button>
  );
};

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  };

  if (element.children[0].bold) {
    style.fontWeight = 'bold';
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic';
  }

  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.user.id.replace(' ', '-')}`}
      className={`inline-block p-1 mx-1 align-baseline rounded bg-gray-200 text-sm ${selected && focused ? 'shadow-outline-blue' : ''
        }`}
    >
      {IS_MAC ? (
        <>
          {children}@{element.user.username}
        </>
      ) : (
        <>
          @{element.user.username}
          {children}
        </>
      )}
    </span>
  );
};

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className="relative"
      >
        <img
          src={element.url}
          className={`block max-w-full max-h-80 ${selected && focused ? 'outline outline-2 outline-blue-500' : ''
            }`}
        />
        <button
          type="button"
          onClick={() =>
            Transforms.removeNodes(editor, { at: path })
          }
          className={`p-1 absolute z-50 top-2 left-2 bg-white hover:bg-red-500 flex items-center justify-center ${selected ? 'inline' : 'hidden'
            }`}
        >
          <Icon className="!m-0" name="trash" size="small" />
        </button>
      </div>
    </div >
  )
}






WYSIWYG.propTypes = {
  isLocked: PropTypes.bool,
  showToolbar: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  mentionList: PropTypes.array,
  /* eslint-enable react/forbid-prop-types */
  editorPlaceholder: PropTypes.string,
};

WYSIWYG.defaultProps = {
  isLocked: false,
  showToolbar: true,
  value: '',
  onChange: () => { },
  mentionList: [],
  editorPlaceholder: 'Enter some rich text…',
};

export default WYSIWYG;

