import { attr } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'GlobalLabel';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    color: attr(),
    isGlobal: attr(),
    autoSyncLabeel: attr(),
  };

  static reducer({ type, payload }, GlobalLabel) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.PROJECT_MEMBER_CREATE_HANDLE:
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE:
        if (payload.globalLabels) {
          payload.globalLabels.forEach((label) => {
            GlobalLabel.upsert(label);
          });
        }
        break;
      case ActionTypes.GLOBAL_LABEL_CREATE:
      case ActionTypes.GLOBAL_LABEL_CREATE_HANDLE:
      case ActionTypes.GLOBAL_LABEL_UPDATE_HANDLE:
      case ActionTypes.GLOBAL_LABEL_UPDATE__SUCCESS:
        GlobalLabel.upsert(payload.label);
        break;

      case ActionTypes.GLOBAL_LABEL_DELETE_HANDLE:
      case ActionTypes.GLOBAL_LABEL_DELETE__SUCCESS:
        {
          const globalModel = GlobalLabel.withId(payload.label.id);
          if (globalModel) {
            globalModel.delete();
          }
        }
        break;

      case ActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE__FAILURE: {
        console.log('label has failed', payload);
        break;
      }

      case ActionTypes.GLOBAL_LABEL_CREATE__SUCCESS:
        GlobalLabel.withId(payload.localId).delete();
        GlobalLabel.upsert(payload.label);
        break;
      case ActionTypes.GLOBAL_LABEL_CREATE__FAILURE:
        GlobalLabel.withId(payload.localId).delete();
        break;
      default:
    }
  }

  static findLabelsFromText(filterText, labels) {
    const selectLabel = filterText.toLocaleLowerCase();
    const matchingLabels = labels.filter((label) =>
      label.name ? label.name.toLocaleLowerCase().startsWith(selectLabel) : false,
    );
    if (matchingLabels.length === 1) {
      // Appens the user to the filter
      return matchingLabels[0].id;
    }
    return null;
  }
}
