/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from "react";
import { Draggable } from "react-beautiful-dnd";
import hash from "object-hash";

export const ListManagerItem = ({
  item,
  index,
  render,
  disabled
}) => (
  <Draggable draggableId={hash(item)} index={index} isDragDisabled={item.id === "add" || disabled}>
    {(provided, _) => (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {render(item)}
      </div>
    )}
  </Draggable>
);
