/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';


import styles from './DescriptionEdit.module.scss';

import Editor from '../Editor';

const DescriptionEdit = React.forwardRef(({ defaultValue, onUpdate, mentionUsers }, ref) => {
  const [t] = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [value, setValue] = useState(defaultValue || '');
  const [updatedValue, setUpdatedValue] = useState(undefined);

  const editorRef = React.useRef();

  useEffect(() =>
    setValue(defaultValue || ''), [defaultValue]);

  const open = useCallback(() => {
    setIsLocked(false);
    editorRef.current.refocusEditor();
  }, []);

  const close = useCallback((event) => {
    const cleanValue = updatedValue || null;

    if (cleanValue && cleanValue.slate !== defaultValue) {
      onUpdate(cleanValue);
    }

    setIsLocked(true);
  }, [defaultValue, onUpdate, updatedValue]);

  const cancel = useCallback(() => {
    editorRef.current.resetEditor();
    setIsLocked(true)
  }, [setIsLocked]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close],
  );


  const handleSubmit = useCallback(() => {
    close();
  }, [close]);

  return (
    <Form onSubmit={handleSubmit} >
      <div
        onClick={() => {
          if (isLocked) {
            open();
          }
        }}
        className="slateEditor"
      >
        <Editor
          ref={editorRef}
          value={value}
          onChange={setUpdatedValue}
          isLocked={isLocked}
          mentionList={mentionUsers}
          editorPlaceholder="Add more detailed description"
        />
        <div className={styles.controls}>
          {!isLocked && (
            <>
              <Button positive content={t('action.save')} />
              <Button negative content="Cancel" onClick={cancel} />
            </>
          )}
        </div>
      </div>
    </Form>
  );
});

DescriptionEdit.propTypes = {
  defaultValue: PropTypes.string,
  mentionUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

DescriptionEdit.defaultProps = {
  defaultValue: '',
};

export default React.memo(DescriptionEdit);
