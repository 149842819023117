import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Button, Form } from 'semantic-ui-react';
import { useDidUpdate, useToggle } from '../../lib/hooks';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './StoryPointsStep.module.scss'

const StoryPointsEditSteps = React.memo(({ defaultValue, onUpdate, onBack, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm(() => {
    const points = defaultValue || 0

    return {
      storyPoints: points,
    };
  });

  const storyPointField = useRef(null);

  const handleSubmit = useCallback(() => {
    if (!defaultValue || defaultValue !== data.storyPoints) {
      onUpdate(data.storyPoints)
    }
    onClose();
  }, [defaultValue, onUpdate, onClose, data]);

  const handleClearClick = useCallback(() => {
    if (defaultValue) {
      onUpdate(null);
    }

    onClose();
  }, [defaultValue, onUpdate, onClose]);

  useEffect(() => {
    storyPointField.current.select();
  }, []);

  const numbers = [1, 2, 3, 5, 8, 13, 21, 24];

  const handleClick = (number) => {
    setData({ storyPoints: number });
  };

  return (
    <>
      <Popup.Header onBack={onBack}>
        Edit Story Points
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldBox}>
              <Input ref={storyPointField} name="storyPoints" type="number" value={data.storyPoints} onChange={handleFieldChange} />
            </div>
          </div>
          <div className={styles.numberGrid} style={{ paddingBottom: '20px' }}>
            {numbers.map(number => (
              <button
                key={number}
                type="button"
                className={`${styles.numberButton} ${data.storyPoints === number ? styles.numberButtonActive : ''}`}
                onClick={() => handleClick(number)}
              >
                <span>{number}</span>
              </button>
            ))}
          </div>
          <Button style={{ paddingTop: '5px' }} positive content={t('action.save')} />
        </Form>
        <div >
          <Button
            style={{ paddingTop: '5px' }}
            negative
            content={t('action.remove')}
            className={styles.deleteButton}
            onClick={handleClearClick}
          />
        </div>
      </Popup.Content>
    </>
  );
});

StoryPointsEditSteps.propTypes = {
  defaultValue: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};
StoryPointsEditSteps.defaultProps = {
  defaultValue: undefined,
  onBack: undefined,
};

export default StoryPointsEditSteps; 
