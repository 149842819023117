import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import requests from '../requests';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createProjectMember(projectId, data) {
  const localId = yield call(createLocalId);

  yield put(
    actions.createProjectMember({
      ...data,
      projectId,
      id: localId,
    }),
  );

  let projectMember;
  try {
    ({ item: projectMember } = yield call(request, api.createProjectMember, projectId, data));
  } catch (error) {
    yield put(actions.createProjectMember.failure(localId, error));
    return;
  }

  yield put(actions.createProjectMember.success(localId, projectMember));
}

export function* createMemberInCurrentProject(data) {
  const { projectId } = yield select(selectors.selectPath);

  yield call(createProjectMember, projectId, data);
}

export function* handleProjectMemberCreate(projectMember) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const isCurrentUser = projectMember.userId === currentUserId;

  let user;
  let project;
  let board;
  let users1;
  let users2;
  let projectManagers;
  let projectMembers;
  let boards;
  let boardMemberships1;
  let boardMemberships2;
  let labels;
  let lists;
  let cards;
  let cardMemberships;
  let cardLabels;
  let tasks;
  let attachments;
  let deletedNotifications;

  if (isCurrentUser) {
    const { boardId } = yield select(selectors.selectPath);

    yield put(
      actions.handleProjectMemberCreate.fetchProject(
        projectMember.projectId,
        currentUserId,
        boardId,
      ),
    );

    try {
      ({
        item: project,
        included: {
          users: users1,
          projectManagers,
          projectMembers,
          boards,
          boardMemberships: boardMemberships1,
        },
      } = yield call(request, api.getProject, projectMember.projectId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(requests.fetchBoardByCurrentPath);
    } catch {} // eslint-disable-line no-empty

    if (body && body.project && body.project.id === projectMember.projectId) {
      ({
        project,
        board,
        users: users2,
        boardMemberships: boardMemberships2,
        labels,
        lists,
        cards,
        cardMemberships,
        cardLabels,
        tasks,
        attachments,
      } = body);

      if (body.card) {
        deletedNotifications = yield select(selectors.selectNotificationsByCardId, body.card.id);
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, projectMember.userId));
    } catch {
      return;
    }
  }

  yield put(
    actions.handleProjectMemberCreate(
      projectMember,
      project,
      board,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      projectManagers,
      projectMembers,
      boards,
      mergeRecords(boardMemberships1, boardMemberships2),
      labels,
      lists,
      cards,
      cardMemberships,
      cardLabels,
      tasks,
      attachments,
      deletedNotifications,
    ),
  );
}

export function* deleteProjectMember(id) {
  let projectMember = yield select(selectors.selectProjectMemberById, id);

  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { projectId } = yield select(selectors.selectPath);

  yield put(
    actions.deleteProjectMember(
      id,
      projectMember.userId === currentUserId,
      projectMember.projectId === projectId,
    ),
  );

  try {
    ({ item: projectMember } = yield call(request, api.deleteProjectMember, id));
  } catch (error) {
    yield put(actions.deleteProjectMember.failure(id, error));
    return;
  }

  yield put(actions.deleteProjectMember.success(projectMember));
}

export function* handleProjectMemberDelete(projectMember) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { projectId } = yield select(selectors.selectPath);

  yield put(
    actions.handleProjectMemberDelete(
      projectMember,
      projectMember.userId === currentUserId,
      projectMember.projectId === projectId,
    ),
  );
}

export default {
  createProjectMember,
  createMemberInCurrentProject,
  handleProjectMemberCreate,
  deleteProjectMember,
  handleProjectMemberDelete,
};
