import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* GlobalLabelsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.GLOBAL_LABEL_CREATE, ({ payload: { data } }) =>
      services.createGlobalLabel(data),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_CREATE_HANDLE, ({ payload: { label } }) =>
      services.handleGlobalLabelCreate(label),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_UPDATE_HANDLE, ({ payload: { label } }) =>
      services.handleGlobalLabelUpdate(label),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_UPDATE, ({ payload: { id, data } }) =>
      services.updateGlobalLabel(id, data),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_DELETE, ({ payload: { id } }) => {
      const newId = id.replace('global-', '');
      return services.deleteGlobalLabel(newId);
    }),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_DELETE_HANDLE, ({ payload: { id } }) =>
      services.handleGlobalLabelDelete(id),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_TO_CARD_ADD_HANDLE, ({ payload: { cardLabel } }) =>
      services.handleGlobalLabelToCardAdd(cardLabel),
    ),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE, ({ payload: { id, cardId } }) => {
      return services.removeGlobalLabelFromCard(id, cardId);
    }),
    takeEvery(EntryActionTypes.GLOBAL_LABEL_FROM_CARD_REMOVE_HANDLE, ({ payload: { cardLabel } }) =>
      services.handleGlobalLabelFromCardRemove(cardLabel),
    ),
  ]);
}
