import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* labelsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.LABEL_IN_CURRENT_BOARD_CREATE, ({ payload: { data } }) =>
      services.createLabelInCurrentBoard(data),
    ),
    takeEvery(EntryActionTypes.LABEL_CREATE_HANDLE, ({ payload: { label } }) =>
      services.handleLabelCreate(label),
    ),
    takeEvery(EntryActionTypes.LABEL_UPDATE, ({ payload: { id, data } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.updateGlobalLabel(newId, data);
      }
      return services.updateLabel(id, data);
    }),
    takeEvery(EntryActionTypes.LABEL_UPDATE_HANDLE, ({ payload: { label } }) =>
      services.handleLabelUpdate(label),
    ),
    takeEvery(EntryActionTypes.LABEL_MOVE, ({ payload: { id, index } }) =>
      services.moveLabel(id, index),
    ),
    takeEvery(EntryActionTypes.LABEL_DELETE, ({ payload: { id } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.deleteGlobalLabel(newId);
      }
      return services.deleteLabel(id);
    }),

    takeEvery(EntryActionTypes.LABEL_DELETE_HANDLE, ({ payload: { label } }) =>
      services.handleLabelDelete(label),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_CARD_ADD, ({ payload: { id, cardId } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.addGlobalLabelToCard(newId, cardId);
      }

      return services.addLabelToCard(id, cardId);
    }),
    takeEvery(EntryActionTypes.LABEL_TO_CURRENT_CARD_ADD, ({ payload: { id } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.addGlobalLabelToCurrentCard(newId);
      }
      return services.addLabelToCurrentCard(id);
    }),
    takeEvery(EntryActionTypes.LABEL_TO_CARD_ADD_HANDLE, ({ payload: { cardLabel } }) =>
      services.handleLabelToCardAdd(cardLabel),
    ),
    takeEvery(EntryActionTypes.LABEL_FROM_CARD_REMOVE, ({ payload: { id, cardId } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.removeGlobalLabelFromCard(newId, cardId);
      }
      return services.removeLabelFromCard(id, cardId);
    }),
    takeEvery(EntryActionTypes.LABEL_FROM_CURRENT_CARD_REMOVE, ({ payload: { id } }) => {
      if (id.startsWith('global-')) {
        const newId = id.replace('global-', '');
        return services.removeGlobalLabelFromCurrentCard(newId);
      }
      return services.removeLabelFromCurrentCard(id);
    }),
    takeEvery(EntryActionTypes.LABEL_FROM_CARD_REMOVE_HANDLE, ({ payload: { cardLabel } }) =>
      services.handleLabelFromCardRemove(cardLabel),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_FILTER_IN_CURRENT_BOARD_ADD, ({ payload: { id } }) =>
      services.addLabelToFilterInCurrentBoard(id),
    ),
    takeEvery(EntryActionTypes.LABEL_FROM_FILTER_IN_CURRENT_BOARD_REMOVE, ({ payload: { id } }) =>
      services.removeLabelFromFilterInCurrentBoard(id),
    ),
  ]);
}
