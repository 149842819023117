import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { usePopup } from '../../../lib/popup';

import NameEdit from './NameEdit';
import ActionsStep from './ActionsStep';
import Linkify from '../../Linkify';

import styles from './Item.module.scss';

import BoardMembershipsStep from '../../BoardMembershipsStep';
import User from '../../User';


const Item = React.memo(
  ({ id, index, name, isCompleted, isPersisted, canEdit, onUpdate, onDelete, users, allBoardMemberships, onUserAddTask, onUserRemoveTask }) => {
    const [t] = useTranslation();
    const nameEdit = useRef(null);

    const handleClick = useCallback(() => {
      if (isPersisted && canEdit) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleToggleChange = useCallback(() => {
      onUpdate({
        isCompleted: !isCompleted,
      });
    }, [isCompleted, onUpdate]);

    const handleNameEdit = useCallback(() => {
      nameEdit.current.open();
    }, [])

    const ActionsPopup = usePopup(ActionsStep);

    const BoardMembershipsPopup = usePopup(BoardMembershipsStep);

    const handleTaskAdd = useCallback((memberId) => {
      onUserAddTask({ memberId, taskId: id })
    }, [onUserAddTask, id])

    const handleTaskDelete = useCallback((memberId) => {
      onUserRemoveTask({ memberId, taskId: id })
    }, [onUserRemoveTask, id])

    const userIds = users.map((user) => user.id);

    return (
      <Draggable draggableId={id} index={index} isDragDisabled={!isPersisted || !canEdit}>
        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
          const contentNode = (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...draggableProps} {...dragHandleProps} ref={innerRef} className={styles.wrapper}>
              <span className={styles.checkboxWrapper}>
                <Checkbox
                  checked={isCompleted}
                  disabled={!isPersisted || !canEdit}
                  className={styles.checkbox}
                  onChange={handleToggleChange}
                />
              </span>
              <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
                <div className={classNames(canEdit && styles.contentHoverable)} style={{
                  display: 'flex'
                }}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                                               jsx-a11y/no-static-element-interactions */}
                  <span
                    className={classNames(styles.text, canEdit && styles.textEditable)}
                    onClick={handleClick}
                  >
                    <span className={classNames(styles.task, isCompleted && styles.taskCompleted)}>
                      <Linkify linkStopPropagation>{name}</Linkify>
                    </span>
                  </span>
                  {/*  Member icons here */}
                  <div className={styles.moduleWrapper} >
                    {users.length > 0 && (
                      <div className={styles.attachments} style={{
                        display: 'flex',
                        paddingRight: '20px'
                      }}>
                        <div style={{
                          display: 'flex'
                        }}>
                          {users.map((user) => (
                            <span key={user.id} className={styles.attachment}>
                              {canEdit ? (
                                <BoardMembershipsPopup
                                  items={allBoardMemberships}
                                  currentUserIds={userIds}
                                  onUserSelect={handleTaskAdd}
                                  onUserDeselect={handleTaskDelete}
                                >
                                  <User name={user.name} avatarUrl={user.avatarUrl} />
                                </BoardMembershipsPopup>
                              ) : (
                                <User name={user.name} avatarUrl={user.avatarUrl} />
                              )}
                            </span>
                          ))}
                        </div>
                        {canEdit && (
                          <BoardMembershipsPopup
                            items={allBoardMemberships}
                            currentUserIds={userIds}
                            onUserSelect={handleTaskAdd}
                            onUserDeselect={handleTaskDelete}
                          >
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                              type="button"
                              className={classNames(styles.attachment, styles.dueDate)}
                            >
                              <Icon name="add" size="small" className={styles.addAttachment} />
                            </button>
                          </BoardMembershipsPopup>
                        )}
                      </div>
                    )}
                  </div>

                  {isPersisted && canEdit && (
                    <ActionsPopup
                      onNameEdit={handleNameEdit}
                      onDelete={onDelete}
                      users={users}
                      allBoardMemberships={allBoardMemberships}
                      onUserAdd={handleTaskAdd}
                      onUserRemove={handleTaskDelete}>
                      <Button className={classNames(styles.button, styles.target)}>
                        <Icon fitted name="cog" size="small" />
                      </Button>
                    </ActionsPopup>
                  )}
                </div>
              </NameEdit>
            </div>
          );

          return isDragging ? ReactDOM.createPortal(contentNode, document.body) : contentNode;
        }}
      </Draggable>
    );
  },
);

Item.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  allBoardMemberships: PropTypes.array.isRequired,
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  onUserAddTask: PropTypes.func.isRequired,
  onUserRemoveTask: PropTypes.func.isRequired,
};

export default Item;
