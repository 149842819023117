/* eslint-disable no-param-reassign */
import { Editor, Transforms, Range, Element as SlateElement, Point } from 'slate';

export const SHORTCUTS = {
  '*': { block: 'list-item' },
  '-': { block: 'list-item' },
  '+': { block: 'list-item' },
  '>': { block: 'blockquote' },
  '#': { block: 'heading', customValues: { depth: 1 } },
  '##': { block: 'heading', customValues: { depth: 2 } },
  '###': { block: 'heading', customValues: { depth: 3 } },
  '####': { block: 'heading', customValues: { depth: 4 } },
  '#####': { blcok: 'heading', customValues: { depth: 5 } },
  '######': { block: 'heading', customValues: { depth: 6 } },
};

export const withShortcuts = (editor) => {
  const { deleteBackward, insertText } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range) + text.slice(0, -1);
      const type = SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor);
        }

        const newProperties = {
          type: type.block,
          ...type.customValues,
        };
        console.log('newProperties', newProperties);
        Transforms.setNodes(editor, newProperties, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        });

        if (type.block === 'list-item') {
          const list = {
            type: 'unorderedList',
            children: [],
          };
          Transforms.wrapNodes(editor, list, {
            match: (n) =>
              !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
          });
        }

        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties = {
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'bulleted-list',
              split: true,
            });
          }

          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

export default {
  withShortcuts,
  SHORTCUTS,
};
