import { dequal } from 'dequal';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon } from 'semantic-ui-react';
import { Input, Popup } from '../../lib/custom-ui';
import ImportStep from './AddStep/ImportStep';
import { useDidUpdate, useToggle } from '../../lib/hooks';

import { useForm, useSteps } from '../../hooks';
import DeleteStep from '../DeleteStep';

import styles from './EditStep.module.scss';

const StepTypes = {
  DELETE: 'DELETE',
  IMPORT: 'IMPORT',
};

const EditStep = React.memo(({ defaultData, onUpdate, onDelete, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    name: '',
    import: null,
    ...defaultData,
  });

  const [step, openStep, handleBack] = useSteps();

  const [focusNameFieldState, focusNameField] = useToggle();
  const nameField = useRef(null);

  const handleImportSelect = useCallback(
    (nextImport) => {
      setData((prevData) => ({
        ...prevData,
        import: nextImport,
      }));
    },
    [setData],
  );

  const handleImportBack = useCallback(() => {
    handleBack();
    focusNameField();
  }, [handleBack, focusNameField]);

  const handleImportClick = useCallback(() => {
    openStep(StepTypes.IMPORT);
  }, [openStep]);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    if (!dequal(cleanData, defaultData)) {
      onUpdate(cleanData);
    }


    onClose();
  }, [defaultData, onUpdate, onClose, data]);

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  useEffect(() => {
    nameField.current.select();
  }, []);


  useDidUpdate(() => {
    nameField.current.focus();
  }, [focusNameFieldState]);

  if (step && step.type === StepTypes.IMPORT) {
    return <ImportStep onSelect={handleImportSelect} onBack={handleImportBack} />;
  }

  if (step && step.type === StepTypes.DELETE) {
    return (
      <DeleteStep
        title="common.deleteBoard"
        content="common.areYouSureYouWantToDeleteThisBoard"
        buttonContent="action.deleteBoard"
        onConfirm={onDelete}
        onBack={handleBack}
      />
    );
  }

  return (
    <>
      <Popup.Header>
        {t('common.editBoard', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <div className={styles.text}>{t('common.title')}</div>
          <Input
            fluid
            ref={nameField}
            name="name"
            value={data.name}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <Button positive content={t('action.save')} />

          <Button type="button" className={styles.importButton} onClick={handleImportClick}>
            <Icon
              name={data.import ? data.import.type : 'arrow down'}
              className={styles.importButtonIcon}
            />
            {data.import ? data.import.file.name : t('action.import')}
          </Button>

        </Form>
        {data.isMainBoard !== true && (
          <Button
            content={t('action.delete')}
            className={styles.deleteButton}
            onClick={handleDeleteClick}
          />
        )}
      </Popup.Content>
    </>
  );
});

EditStep.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditStep;
