/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-else-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable consistent-return */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */

import { Transforms, Editor, Range, Element as SlateElement } from 'slate';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';

import { useSlateStatic } from 'slate-react';

import { Button, Icon } from '../../components';

import { getProxy } from '../../../../api/proxy';

export const withImages = (editor) => {
  const { insertData, isVoid, isInline } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.isInline = (element) => (element.type === 'link' ? true : isInline(element));

  editor.insertData = async (data) => {
    const text = data.getData('text/plain');
    const { files } = data;
    // TODO: upload to planka server and get back url?
    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isEmbedableLink(text)) {
      const embedUrl = await getEmbedUrl(text);
      if (embedUrl) {
        insertImage(editor, embedUrl);
      }
    }
    // only use isEmbedAbleLink to prevent ip grabbers etc. Link has to be on the whitelist to be able to be auto called
    // else if (isImageUrl(text)) {
    //     insertImage(editor, text);
    //   }
    else if (isUrl(text)) {
      insertLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const embedableLinks = [
  'https://gyazo.com',
  'https://imgur.com',
  'https://i.gyazo.com',
  'https://i.imgur.com',
  'https://prnt.sc',
  'https://img001.prntscr.com',
];

const proxyImage = async (url) => {
  const cookies = document.cookie;
  const output = {};
  cookies.split(/\s*;\s*/).forEach(function (pair) {
    pair = pair.split(/\s*=\s*/);
    output[pair[0]] = pair.splice(1).join('=');
  });
  return getProxy(
    { url },
    { 'Content-Type': 'application/json', Authorization: `Bearer ${output.accessToken}` },
  );
};

const embedableLinksTable = {
  'https://gyazo.com': {
    regex: /https:\/\/gyazo\.com\/([a-zA-Z0-9]+)$/,
    embedUrl: async (url, match) => proxyImage(url),
  },
  'https://i.gyazo.com': {
    embedUrl: (url) => url,
  },
  'https://imgur.com': {
    regex: /https:\/\/imgur\.com\/(?!a\/)([a-zA-Z0-9]+)$/,
    embedUrl: (url, match) => {
      const id = match[1];
      return `https://i.imgur.com/${id}.png`;
    },
  },
  'https://i.imgur.com': {
    embedUrl: (url) => url,
  },
  'https://prnt.sc': {
    embedUrl: async (url) => proxyImage(url),
  },
  'https://img001.prntscr.com': {
    embedUrl: async (url) => url,
  },
};

const isEmbedableLink = (url) => {
  const baseLink = embedableLinks.find((baseLink) => url.startsWith(baseLink));

  if (!baseLink) {
    return false;
  }

  const blacklistRegex = embedableLinksTable[baseLink]?.blacklistRegex;
  if (blacklistRegex && blacklistRegex.test(url)) {
    return false;
  }

  if (!embedableLinksTable[baseLink]?.regex) return true;
  const regex = embedableLinksTable[baseLink]?.regex;
  return regex && regex.test(url);
};

const getEmbedUrl = (url) => {
  let baseLink = embedableLinks.find((baseLink) => url.startsWith(baseLink));
  if (!baseLink) return null;
  if (baseLink.endsWith('/')) baseLink = baseLink.slice(0, -1);

  const regex = embedableLinksTable[baseLink]?.regex;
  const match = url.match(regex);

  return match ? embedableLinksTable[baseLink].embedUrl(url, match) : null;
};

export const insertImage = (editor, url) => {
  const text = { text: '' };
  const image = { type: 'image', url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  });
};

export const InsertImageButton = () => {
  const editor = useSlateStatic();
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        // TODO: make a actual popup
        const url = window.prompt('Enter the URL of the image:');
        if (url && !isImageUrl(url)) {
          alert('URL is not an image');
          return;
        }
        url && insertImage(editor, url);
      }}
    >
      <Icon name="image" size="small" />
    </Button>
  );
};

export const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext);
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link' });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'link',
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  if (isCollapsed) {
    // If no text is selected, insert the link as a placeholder text
    const link = {
      type: 'link',
      url,
      children: [{ text: url }],
    };
    Transforms.insertNodes(editor, link);

    // Move the selection after the inserted link
    Transforms.move(editor, { distance: 1, unit: 'offset' });
  } else {
    // Apply the link to selected text
    Transforms.wrapNodes(
      editor,
      {
        type: 'link',
        url,
        children: [],
      },
      { split: true },
    );
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export default { withImages, isImageUrl, insertImage, insertLink, InsertImageButton };
