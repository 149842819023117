import ActionTypes from '../constants/ActionTypes';

const createProjectMember = (projectMember) => ({
  type: ActionTypes.PROJECT_MEMBER_CREATE,
  payload: {
    projectMember,
  },
});

createProjectMember.success = (localId, projectMember) => ({
  type: ActionTypes.PROJECT_MEMBER_CREATE__SUCCESS,
  payload: {
    localId,
    projectMember,
  },
});

createProjectMember.failure = (localId, error) => ({
  type: ActionTypes.PROJECT_MEMBER_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleProjectMemberCreate = (
  projectManager,
  projectMember,
  project,
  board,
  users,
  projectMembers,
  projectManagers,
  boards,
  boardMemberships,
  labels,
  lists,
  cards,
  cardMemberships,
  cardLabels,
  tasks,
  attachments,
  deletedNotifications,
) => ({
  type: ActionTypes.PROJECT_MEMBER_CREATE_HANDLE,
  payload: {
    projectManager,
    projectMember,
    project,
    board,
    users,
    projectManagers,
    projectMembers,
    boards,
    boardMemberships,
    labels,
    lists,
    cards,
    cardMemberships,
    cardLabels,
    tasks,
    attachments,
    deletedNotifications,
  },
});

handleProjectMemberCreate.fetchProject = (id, currentUserId, currentBoardId) => ({
  type: ActionTypes.PROJECT_MEMBER_CREATE_HANDLE__PROJECT_FETCH,
  payload: {
    id,
    currentUserId,
    currentBoardId,
  },
});

const deleteProjectMember = (id, isCurrentUser, isCurrentProject) => ({
  type: ActionTypes.PROJECT_MEMBER_DELETE,
  payload: {
    id,
    isCurrentUser,
    isCurrentProject,
  },
});

deleteProjectMember.success = (projectMember) => ({
  type: ActionTypes.PROJECT_MEMBER_DELETE__SUCCESS,
  payload: {
    projectMember,
  },
});

deleteProjectMember.failure = (id, error) => ({
  type: ActionTypes.PROJECT_MEMBER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleProjectMemberDelete = (projectMember, isCurrentUser, isCurrentProject) => ({
  type: ActionTypes.PROJECT_MEMBER_DELETE_HANDLE,
  payload: {
    projectMember,
    isCurrentUser,
    isCurrentProject,
  },
});

export default {
  createProjectMember,
  handleProjectMemberCreate,
  deleteProjectMember,
  handleProjectMemberDelete,
};
