export function computeOriginalIndex(maxItems, chunkIndex, indexInChunk) {
  return chunkIndex * maxItems + indexInChunk;
}

export function computeOriginalIndexAfterDrop(
  maxItems,
  sourceChunkIndex,
  destinationChunkIndex,
  indexInChunk,
) {
  return (
    destinationChunkIndex * maxItems +
    indexInChunk +
    (sourceChunkIndex < destinationChunkIndex ? -1 : 0)
  );
}
